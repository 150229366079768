import { dew as _CharacterDataImplDew } from "./CharacterData-impl";
import { dew as _nodeTypeDew } from "../node-type";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const CharacterDataImpl = _CharacterDataImplDew().implementation;

  const NODE_TYPE = _nodeTypeDew();

  class ProcessingInstructionImpl extends CharacterDataImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, privateData);
      this.nodeType = NODE_TYPE.PROCESSING_INSTRUCTION_NODE;
      this._target = privateData.target;
    }

    get target() {
      return this._target;
    }

  }

  exports = {
    implementation: ProcessingInstructionImpl
  };
  return exports;
}