import { dew as _internalConstantsDew } from "../helpers/internal-constants";
import { dew as _namespacesDew } from "../helpers/namespaces";
import { dew as _utilsDew } from "../../utils";
import { dew as _SVGAnimatedStringDew } from "../generated/SVGAnimatedString";
import { dew as _ElementImplDew } from "./Element-impl";
import { dew as _ElementCSSInlineStyleImplDew } from "./ElementCSSInlineStyle-impl";
import { dew as _GlobalEventHandlersImplDew } from "./GlobalEventHandlers-impl";
import { dew as _HTMLOrSVGElementImplDew } from "./HTMLOrSVGElement-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    domSymbolTree
  } = _internalConstantsDew();

  const {
    SVG_NS
  } = _namespacesDew();

  const {
    mixin
  } = _utilsDew();

  const SVGAnimatedString = _SVGAnimatedStringDew();

  const ElementImpl = _ElementImplDew().implementation;

  const ElementCSSInlineStyleImpl = _ElementCSSInlineStyleImplDew().implementation;

  const GlobalEventHandlersImpl = _GlobalEventHandlersImplDew().implementation;

  const HTMLOrSVGElementImpl = _HTMLOrSVGElementImplDew().implementation;

  class SVGElementImpl extends ElementImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, privateData);

      this._initHTMLOrSVGElement();

      this._initElementCSSInlineStyle();

      this._initGlobalEvents();
    } // Keep in sync with HTMLElement. https://github.com/jsdom/jsdom/issues/2599


    _attrModified(name, value, oldValue) {
      if (name === "style" && value !== oldValue && !this._settingCssText) {
        this._settingCssText = true;
        this._style.cssText = value;
        this._settingCssText = false;
      } else if (name.startsWith("on")) {
        this._globalEventChanged(name.substring(2));
      }

      super._attrModified(name, value, oldValue);
    }

    get className() {
      return SVGAnimatedString.createImpl(this._globalObject, [], {
        element: this,
        attribute: "class"
      });
    }

    get ownerSVGElement() {
      let e = domSymbolTree.parent(this);

      while (e && e.namespaceURI === SVG_NS) {
        if (e.localName === "svg") {
          return e;
        }

        e = domSymbolTree.parent(e);
      }

      return null;
    }

    get viewportElement() {
      // TODO: <symbol>/<use> may make this different from ownerSVGElement.
      return this.ownerSVGElement;
    }

  }

  SVGElementImpl.attributeRegistry = new Map();
  mixin(SVGElementImpl.prototype, ElementCSSInlineStyleImpl.prototype);
  mixin(SVGElementImpl.prototype, GlobalEventHandlersImpl.prototype);
  mixin(SVGElementImpl.prototype, HTMLOrSVGElementImpl.prototype);
  exports.implementation = SVGElementImpl;
  return exports;
}