import { dew as _utilsDew } from "../../utils";
import { dew as _internalConstantsDew } from "../helpers/internal-constants";
import { dew as _nodeTypeDew } from "../node-type";
import { dew as _NodeImplDew } from "./Node-impl";
import { dew as _NonElementParentNodeImplDew } from "./NonElementParentNode-impl";
import { dew as _ParentNodeImplDew } from "./ParentNode-impl";
import { dew as _utils2Dew } from "../generated/utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    mixin
  } = _utilsDew();

  const {
    domSymbolTree
  } = _internalConstantsDew();

  const NODE_TYPE = _nodeTypeDew();

  const NodeImpl = _NodeImplDew().implementation;

  const NonElementParentNodeImpl = _NonElementParentNodeImplDew().implementation;

  const ParentNodeImpl = _ParentNodeImplDew().implementation;

  const idlUtils = _utils2Dew();

  class DocumentFragmentImpl extends NodeImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, {
        ownerDocument: idlUtils.implForWrapper(globalObject._document),
        ...privateData
      });
      const {
        host
      } = privateData;
      this._host = host;
      this.nodeType = NODE_TYPE.DOCUMENT_FRAGMENT_NODE;
    } // This is implemented separately for Document (which has a _ids cache) and DocumentFragment (which does not).


    getElementById(id) {
      if (id === "") {
        return null;
      }

      for (const descendant of domSymbolTree.treeIterator(this)) {
        if (descendant.nodeType === NODE_TYPE.ELEMENT_NODE && descendant.getAttributeNS(null, "id") === id) {
          return descendant;
        }
      }

      return null;
    }

  }

  mixin(DocumentFragmentImpl.prototype, NonElementParentNodeImpl.prototype);
  mixin(DocumentFragmentImpl.prototype, ParentNodeImpl.prototype);
  exports = {
    implementation: DocumentFragmentImpl
  };
  return exports;
}