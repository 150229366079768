import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _NodeDew } from "./Node.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _DocumentFragmentImplDew } from "../nodes/DocumentFragment-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const Node = _NodeDew();

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;
  const interfaceName = "DocumentFragment";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'DocumentFragment'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["DocumentFragment"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    Node._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class DocumentFragment extends globalObject.Node {
      constructor() {
        return exports.setup(Object.create(new.target.prototype), globalObject, undefined);
      }

      getElementById(elementId) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getElementById' called on an object that is not a valid instance of DocumentFragment.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'getElementById' on 'DocumentFragment': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getElementById' on 'DocumentFragment': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].getElementById(...args));
      }

      prepend() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'prepend' called on an object that is not a valid instance of DocumentFragment.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'prepend' on 'DocumentFragment': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].prepend(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      append() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'append' called on an object that is not a valid instance of DocumentFragment.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'append' on 'DocumentFragment': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].append(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      replaceChildren() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'replaceChildren' called on an object that is not a valid instance of DocumentFragment.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'replaceChildren' on 'DocumentFragment': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].replaceChildren(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      querySelector(selectors) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'querySelector' called on an object that is not a valid instance of DocumentFragment.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'querySelector' on 'DocumentFragment': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'querySelector' on 'DocumentFragment': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].querySelector(...args));
      }

      querySelectorAll(selectors) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'querySelectorAll' called on an object that is not a valid instance of DocumentFragment.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'querySelectorAll' on 'DocumentFragment': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'querySelectorAll' on 'DocumentFragment': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].querySelectorAll(...args));
      }

      get children() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get children' called on an object that is not a valid instance of DocumentFragment.");
        }

        return utils.getSameObject(this, "children", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["children"]);
        });
      }

      get firstElementChild() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get firstElementChild' called on an object that is not a valid instance of DocumentFragment.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["firstElementChild"]);
      }

      get lastElementChild() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get lastElementChild' called on an object that is not a valid instance of DocumentFragment.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["lastElementChild"]);
      }

      get childElementCount() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get childElementCount' called on an object that is not a valid instance of DocumentFragment.");
        }

        return esValue[implSymbol]["childElementCount"];
      }

    }

    Object.defineProperties(DocumentFragment.prototype, {
      getElementById: {
        enumerable: true
      },
      prepend: {
        enumerable: true
      },
      append: {
        enumerable: true
      },
      replaceChildren: {
        enumerable: true
      },
      querySelector: {
        enumerable: true
      },
      querySelectorAll: {
        enumerable: true
      },
      children: {
        enumerable: true
      },
      firstElementChild: {
        enumerable: true
      },
      lastElementChild: {
        enumerable: true
      },
      childElementCount: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "DocumentFragment",
        configurable: true
      },
      [Symbol.unscopables]: {
        value: {
          prepend: true,
          append: true,
          replaceChildren: true,
          __proto__: null
        },
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = DocumentFragment;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: DocumentFragment
    });
  };

  const Impl = _DocumentFragmentImplDew();

  return exports;
}