import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (!utils.isObject(value)) {
      throw new globalObject.TypeError(`${context} is not an object.`);
    }

    function callTheUserObjectsOperation(node) {
      let thisArg = utils.tryWrapperForImpl(this);
      let O = value;
      let X = O;

      if (typeof O !== "function") {
        X = O["acceptNode"];

        if (typeof X !== "function") {
          throw new globalObject.TypeError(`${context} does not correctly implement NodeFilter.`);
        }

        thisArg = O;
      }

      node = utils.tryWrapperForImpl(node);
      let callResult = Reflect.apply(X, thisArg, [node]);
      callResult = conversions["unsigned short"](callResult, {
        context: context,
        globals: globalObject
      });
      return callResult;
    }

    callTheUserObjectsOperation[utils.wrapperSymbol] = value;
    callTheUserObjectsOperation.objectReference = value;
    return callTheUserObjectsOperation;
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    const NodeFilter = () => {
      throw new globalObject.TypeError("Illegal invocation");
    };

    Object.defineProperties(NodeFilter, {
      FILTER_ACCEPT: {
        value: 1,
        enumerable: true
      },
      FILTER_REJECT: {
        value: 2,
        enumerable: true
      },
      FILTER_SKIP: {
        value: 3,
        enumerable: true
      },
      SHOW_ALL: {
        value: 4294967295,
        enumerable: true
      },
      SHOW_ELEMENT: {
        value: 1,
        enumerable: true
      },
      SHOW_ATTRIBUTE: {
        value: 2,
        enumerable: true
      },
      SHOW_TEXT: {
        value: 4,
        enumerable: true
      },
      SHOW_CDATA_SECTION: {
        value: 8,
        enumerable: true
      },
      SHOW_ENTITY_REFERENCE: {
        value: 16,
        enumerable: true
      },
      SHOW_ENTITY: {
        value: 32,
        enumerable: true
      },
      SHOW_PROCESSING_INSTRUCTION: {
        value: 64,
        enumerable: true
      },
      SHOW_COMMENT: {
        value: 128,
        enumerable: true
      },
      SHOW_DOCUMENT: {
        value: 256,
        enumerable: true
      },
      SHOW_DOCUMENT_TYPE: {
        value: 512,
        enumerable: true
      },
      SHOW_DOCUMENT_FRAGMENT: {
        value: 1024,
        enumerable: true
      },
      SHOW_NOTATION: {
        value: 2048,
        enumerable: true
      }
    });
    Object.defineProperty(globalObject, "NodeFilter", {
      configurable: true,
      writable: true,
      value: NodeFilter
    });
  };

  return exports;
}