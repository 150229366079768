import { dew as _nodeTypeDew } from "../node-type";
import { dew as _nodeDew } from "../helpers/node";
import { dew as _shadowDomDew } from "../helpers/shadow-dom";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const NODE_TYPE = _nodeTypeDew();

  const {
    nodeRoot
  } = _nodeDew();

  const {
    retarget
  } = _shadowDomDew();

  class DocumentOrShadowRootImpl {
    get activeElement() {
      let candidate = this._ownerDocument._lastFocusedElement || this._ownerDocument.body;

      if (!candidate) {
        return null;
      }

      candidate = retarget(candidate, this);

      if (nodeRoot(candidate) !== this) {
        return null;
      }

      if (candidate.nodeType !== NODE_TYPE.DOCUMENT_NODE) {
        return candidate;
      }

      if (candidate.body !== null) {
        return candidate.body;
      }

      return candidate.documentElement;
    }

  }

  exports = {
    implementation: DocumentOrShadowRootImpl
  };
  return exports;
}