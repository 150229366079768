var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports.implementation = class NavigatorLanguageImpl {
    get language() {
      return "en-US";
    } // See Navigator constructor for languages


  };
  return exports;
}