import { dew as _utilsDew } from "../generated/utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const idlUtils = _utilsDew();

  exports.implementation = class MimeTypeArray {
    get length() {
      return 0;
    }

    item() {
      return null;
    }

    namedItem() {
      return null;
    }

    get [idlUtils.supportedPropertyIndices]() {
      return [];
    }

  };
  return exports;
}