import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _DocumentDew } from "../generated/Document";
import { dew as _DocumentFragmentDew } from "../generated/DocumentFragment";
import { dew as _internalConstantsDew } from "../helpers/internal-constants";
import { dew as _nodeDew } from "../node";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const Document = _DocumentDew();

  const DocumentFragment = _DocumentFragmentDew();

  const {
    cloningSteps,
    domSymbolTree
  } = _internalConstantsDew();

  const {
    clone
  } = _nodeDew();

  class HTMLTemplateElementImpl extends HTMLElementImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, privateData);

      const doc = this._appropriateTemplateContentsOwnerDocument(this._ownerDocument);

      this._templateContents = DocumentFragment.createImpl(this._globalObject, [], {
        ownerDocument: doc,
        host: this
      });
    } // https://html.spec.whatwg.org/multipage/scripting.html#appropriate-template-contents-owner-document


    _appropriateTemplateContentsOwnerDocument(doc) {
      if (!doc._isInertTemplateDocument) {
        if (doc._associatedInertTemplateDocument === undefined) {
          const newDoc = Document.createImpl(this._globalObject, [], {
            options: {
              parsingMode: doc._parsingMode,
              encoding: doc._encoding
            }
          });
          newDoc._isInertTemplateDocument = true;
          doc._associatedInertTemplateDocument = newDoc;
        }

        doc = doc._associatedInertTemplateDocument;
      }

      return doc;
    } // https://html.spec.whatwg.org/multipage/scripting.html#template-adopting-steps


    _adoptingSteps() {
      const doc = this._appropriateTemplateContentsOwnerDocument(this._ownerDocument);

      doc._adoptNode(this._templateContents);
    }

    get content() {
      return this._templateContents;
    }

    [cloningSteps](copy, node, document, cloneChildren) {
      if (!cloneChildren) {
        return;
      }

      for (const child of domSymbolTree.childrenIterator(node._templateContents)) {
        const childCopy = clone(child, copy._templateContents._ownerDocument, true);

        copy._templateContents.appendChild(childCopy);
      }
    }

  }

  exports = {
    implementation: HTMLTemplateElementImpl
  };
  return exports;
}