import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _EventModifierInitDew } from "./EventModifierInit.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const EventModifierInit = _EventModifierInitDew();

  exports._convertInherit = (globalObject, obj, ret, {
    context = "The provided value"
  } = {}) => {
    EventModifierInit._convertInherit(globalObject, obj, ret, {
      context
    });

    {
      const key = "charCode";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["unsigned long"](value, {
          context: context + " has member 'charCode' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = 0;
      }
    }
    {
      const key = "code";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["DOMString"](value, {
          context: context + " has member 'code' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = "";
      }
    }
    {
      const key = "isComposing";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["boolean"](value, {
          context: context + " has member 'isComposing' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = false;
      }
    }
    {
      const key = "key";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["DOMString"](value, {
          context: context + " has member 'key' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = "";
      }
    }
    {
      const key = "keyCode";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["unsigned long"](value, {
          context: context + " has member 'keyCode' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = 0;
      }
    }
    {
      const key = "location";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["unsigned long"](value, {
          context: context + " has member 'location' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = 0;
      }
    }
    {
      const key = "repeat";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["boolean"](value, {
          context: context + " has member 'repeat' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = false;
      }
    }
  };

  exports.convert = (globalObject, obj, {
    context = "The provided value"
  } = {}) => {
    if (obj !== undefined && typeof obj !== "object" && typeof obj !== "function") {
      throw new globalObject.TypeError(`${context} is not an object.`);
    }

    const ret = Object.create(null);

    exports._convertInherit(globalObject, obj, ret, {
      context
    });

    return ret;
  };

  return exports;
}