import { dew as _internalConstantsDew } from "../helpers/internal-constants";
import { dew as _nodeTypeDew } from "../node-type";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    domSymbolTree
  } = _internalConstantsDew();

  const NODE_TYPE = _nodeTypeDew();

  class NonDocumentTypeChildNodeImpl {
    get nextElementSibling() {
      for (const sibling of domSymbolTree.nextSiblingsIterator(this)) {
        if (sibling.nodeType === NODE_TYPE.ELEMENT_NODE) {
          return sibling;
        }
      }

      return null;
    }

    get previousElementSibling() {
      for (const sibling of domSymbolTree.previousSiblingsIterator(this)) {
        if (sibling.nodeType === NODE_TYPE.ELEMENT_NODE) {
          return sibling;
        }
      }

      return null;
    }

  }

  exports = {
    implementation: NonDocumentTypeChildNodeImpl
  };
  return exports;
}