import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _formControlsDew } from "../helpers/form-controls";
import { dew as _namespacesDew } from "../helpers/namespaces";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const {
    formOwner
  } = _formControlsDew();

  const {
    HTML_NS
  } = _namespacesDew();

  class HTMLLegendElementImpl extends HTMLElementImpl {
    get form() {
      const parent = this.parentNode;

      if (parent && parent._localName === "fieldset" && parent.namespaceURI === HTML_NS) {
        return formOwner(parent);
      }

      return null;
    }

  }

  exports = {
    implementation: HTMLLegendElementImpl
  };
  return exports;
}