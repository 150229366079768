import { dew as _XMLHttpRequestEventTargetImplDew } from "./XMLHttpRequestEventTarget-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const XMLHttpRequestEventTargetImpl = _XMLHttpRequestEventTargetImplDew().implementation;

  exports.implementation = class XMLHttpRequestUploadImpl extends XMLHttpRequestEventTargetImpl {};
  return exports;
}