import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _HTMLFormElementImplDew } from "../nodes/HTMLFormElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const HTMLElement = _HTMLElementDew();

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;
  const interfaceName = "HTMLFormElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLFormElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLFormElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLFormElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      submit() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'submit' called on an object that is not a valid instance of HTMLFormElement.");
        }

        return esValue[implSymbol].submit();
      }

      requestSubmit() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'requestSubmit' called on an object that is not a valid instance of HTMLFormElement.");
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg !== undefined) {
            curArg = HTMLElement.convert(globalObject, curArg, {
              context: "Failed to execute 'requestSubmit' on 'HTMLFormElement': parameter 1"
            });
          }

          args.push(curArg);
        }
        return esValue[implSymbol].requestSubmit(...args);
      }

      reset() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'reset' called on an object that is not a valid instance of HTMLFormElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].reset();
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      checkValidity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'checkValidity' called on an object that is not a valid instance of HTMLFormElement.");
        }

        return esValue[implSymbol].checkValidity();
      }

      reportValidity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'reportValidity' called on an object that is not a valid instance of HTMLFormElement.");
        }

        return esValue[implSymbol].reportValidity();
      }

      get acceptCharset() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get acceptCharset' called on an object that is not a valid instance of HTMLFormElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "accept-charset");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set acceptCharset(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set acceptCharset' called on an object that is not a valid instance of HTMLFormElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'acceptCharset' property on 'HTMLFormElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "accept-charset", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get action() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get action' called on an object that is not a valid instance of HTMLFormElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["action"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set action(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set action' called on an object that is not a valid instance of HTMLFormElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'action' property on 'HTMLFormElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["action"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get enctype() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get enctype' called on an object that is not a valid instance of HTMLFormElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["enctype"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set enctype(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set enctype' called on an object that is not a valid instance of HTMLFormElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'enctype' property on 'HTMLFormElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["enctype"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get method() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get method' called on an object that is not a valid instance of HTMLFormElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["method"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set method(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set method' called on an object that is not a valid instance of HTMLFormElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'method' property on 'HTMLFormElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["method"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get name() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get name' called on an object that is not a valid instance of HTMLFormElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "name");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set name(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set name' called on an object that is not a valid instance of HTMLFormElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'name' property on 'HTMLFormElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "name", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get noValidate() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get noValidate' called on an object that is not a valid instance of HTMLFormElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "novalidate");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set noValidate(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set noValidate' called on an object that is not a valid instance of HTMLFormElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'noValidate' property on 'HTMLFormElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "novalidate", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "novalidate");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get target() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get target' called on an object that is not a valid instance of HTMLFormElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "target");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set target(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set target' called on an object that is not a valid instance of HTMLFormElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'target' property on 'HTMLFormElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "target", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get elements() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get elements' called on an object that is not a valid instance of HTMLFormElement.");
        }

        return utils.getSameObject(this, "elements", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["elements"]);
        });
      }

      get length() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get length' called on an object that is not a valid instance of HTMLFormElement.");
        }

        return esValue[implSymbol]["length"];
      }

    }

    Object.defineProperties(HTMLFormElement.prototype, {
      submit: {
        enumerable: true
      },
      requestSubmit: {
        enumerable: true
      },
      reset: {
        enumerable: true
      },
      checkValidity: {
        enumerable: true
      },
      reportValidity: {
        enumerable: true
      },
      acceptCharset: {
        enumerable: true
      },
      action: {
        enumerable: true
      },
      enctype: {
        enumerable: true
      },
      method: {
        enumerable: true
      },
      name: {
        enumerable: true
      },
      noValidate: {
        enumerable: true
      },
      target: {
        enumerable: true
      },
      elements: {
        enumerable: true
      },
      length: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLFormElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLFormElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLFormElement
    });
  };

  const Impl = _HTMLFormElementImplDew();

  return exports;
}