import { dew as _nodeDew } from "../helpers/node";
import { dew as _utilsDew } from "../../utils";
import { dew as _DocumentFragmentImplDew } from "./DocumentFragment-impl";
import { dew as _DocumentOrShadowRootImplDew } from "./DocumentOrShadowRoot-impl";
import { dew as _InnerHTMLImplDew } from "../domparsing/InnerHTML-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    nodeRoot
  } = _nodeDew();

  const {
    mixin
  } = _utilsDew();

  const DocumentFragment = _DocumentFragmentImplDew().implementation;

  const DocumentOrShadowRootImpl = _DocumentOrShadowRootImplDew().implementation;

  const InnerHTMLImpl = _InnerHTMLImplDew().implementation;

  class ShadowRootImpl extends DocumentFragment {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, privateData);
      const {
        mode
      } = privateData;
      this._mode = mode;
    }

    _getTheParent(event) {
      if (!event.composed && this === nodeRoot(event._path[0].item)) {
        return null;
      }

      return this._host;
    }

    get mode() {
      return this._mode;
    }

    get host() {
      return this._host;
    }

  }

  mixin(ShadowRootImpl.prototype, DocumentOrShadowRootImpl.prototype);
  mixin(ShadowRootImpl.prototype, InnerHTMLImpl.prototype);
  exports = {
    implementation: ShadowRootImpl
  };
  return exports;
}