import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

import { dew as _utilsDew } from "../generated/utils.js";
import { dew as _attributesDew } from "../attributes.js";
import { dew as _namespacesDew } from "../helpers/namespaces";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const DOMException = _webidl2jsWrapper;

  const idlUtils = _utilsDew();

  const attributes = _attributesDew();

  const {
    HTML_NS
  } = _namespacesDew();

  exports.implementation = class NamedNodeMapImpl {
    constructor(globalObject, args, privateData) {
      this._element = privateData.element;
      this._globalObject = globalObject;
    }

    get _attributeList() {
      return this._element._attributeList;
    }

    get [idlUtils.supportedPropertyIndices]() {
      return this._attributeList.keys();
    }

    get length() {
      return this._attributeList.length;
    }

    item(index) {
      if (index >= this._attributeList.length) {
        return null;
      }

      return this._attributeList[index];
    }

    get [idlUtils.supportedPropertyNames]() {
      const names = new Set(this._attributeList.map(a => a._qualifiedName));
      const el = this._element;

      if (el._namespaceURI === HTML_NS && el._ownerDocument._parsingMode === "html") {
        for (const name of names) {
          const lowercaseName = name.toLowerCase();

          if (lowercaseName !== name) {
            names.delete(name);
          }
        }
      }

      return names;
    }

    getNamedItem(qualifiedName) {
      return attributes.getAttributeByName(this._element, qualifiedName);
    }

    getNamedItemNS(namespace, localName) {
      return attributes.getAttributeByNameNS(this._element, namespace, localName);
    }

    setNamedItem(attr) {
      // eslint-disable-next-line no-restricted-properties
      return attributes.setAttribute(this._element, attr);
    }

    setNamedItemNS(attr) {
      // eslint-disable-next-line no-restricted-properties
      return attributes.setAttribute(this._element, attr);
    }

    removeNamedItem(qualifiedName) {
      const attr = attributes.removeAttributeByName(this._element, qualifiedName);

      if (attr === null) {
        throw DOMException.create(this._globalObject, ["Tried to remove an attribute that was not present", "NotFoundError"]);
      }

      return attr;
    }

    removeNamedItemNS(namespace, localName) {
      const attr = attributes.removeAttributeByNameNS(this._element, namespace, localName);

      if (attr === null) {
        throw DOMException.create(this._globalObject, ["Tried to remove an attribute that was not present", "NotFoundError"]);
      }

      return attr;
    }

  };
  return exports;
}