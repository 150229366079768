import { dew as _PageTransitionEventDew } from "../generated/PageTransitionEvent.js";
import { dew as _eventsDew } from "./events";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const PageTransitionEvent = _PageTransitionEventDew();

  const {
    fireAnEvent
  } = _eventsDew(); // https://html.spec.whatwg.org/multipage/browsing-the-web.html#fire-a-page-transition-event


  exports.fireAPageTransitionEvent = (eventName, window, persisted) => {
    const attributes = {
      persisted,
      cancelable: true,
      bubbles: true
    };
    fireAnEvent(eventName, window, PageTransitionEvent, attributes, true);
  };

  return exports;
}