import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _StorageDew } from "./Storage.js";
import { dew as _EventInitDew } from "./EventInit.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const Storage = _StorageDew();

  const EventInit = _EventInitDew();

  exports._convertInherit = (globalObject, obj, ret, {
    context = "The provided value"
  } = {}) => {
    EventInit._convertInherit(globalObject, obj, ret, {
      context
    });

    {
      const key = "key";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        if (value === null || value === undefined) {
          value = null;
        } else {
          value = conversions["DOMString"](value, {
            context: context + " has member 'key' that",
            globals: globalObject
          });
        }

        ret[key] = value;
      } else {
        ret[key] = null;
      }
    }
    {
      const key = "newValue";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        if (value === null || value === undefined) {
          value = null;
        } else {
          value = conversions["DOMString"](value, {
            context: context + " has member 'newValue' that",
            globals: globalObject
          });
        }

        ret[key] = value;
      } else {
        ret[key] = null;
      }
    }
    {
      const key = "oldValue";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        if (value === null || value === undefined) {
          value = null;
        } else {
          value = conversions["DOMString"](value, {
            context: context + " has member 'oldValue' that",
            globals: globalObject
          });
        }

        ret[key] = value;
      } else {
        ret[key] = null;
      }
    }
    {
      const key = "storageArea";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        if (value === null || value === undefined) {
          value = null;
        } else {
          value = Storage.convert(globalObject, value, {
            context: context + " has member 'storageArea' that"
          });
        }

        ret[key] = value;
      } else {
        ret[key] = null;
      }
    }
    {
      const key = "url";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["USVString"](value, {
          context: context + " has member 'url' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = "";
      }
    }
  };

  exports.convert = (globalObject, obj, {
    context = "The provided value"
  } = {}) => {
    if (obj !== undefined && typeof obj !== "object" && typeof obj !== "function") {
      throw new globalObject.TypeError(`${context} is not an object.`);
    }

    const ret = Object.create(null);

    exports._convertInherit(globalObject, obj, ret, {
      context
    });

    return ret;
  };

  return exports;
}