import { dew as _utilsDew } from "../../utils";
import { dew as _DOMTokenListDew } from "../generated/DOMTokenList";
import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _HTMLHyperlinkElementUtilsImplDew } from "./HTMLHyperlinkElementUtils-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    mixin
  } = _utilsDew();

  const DOMTokenList = _DOMTokenListDew();

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const HTMLHyperlinkElementUtilsImpl = _HTMLHyperlinkElementUtilsImplDew().implementation;

  class HTMLAnchorElementImpl extends HTMLElementImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, privateData);

      this._htmlHyperlinkElementUtilsSetup();

      this._hasActivationBehavior = true;
    }

    _activationBehavior() {
      this._followAHyperlink();
    }

    get relList() {
      if (this._relList === undefined) {
        this._relList = DOMTokenList.createImpl(this._globalObject, [], {
          element: this,
          attributeLocalName: "rel"
        });
      }

      return this._relList;
    }

    get text() {
      return this.textContent;
    }

    set text(v) {
      this.textContent = v;
    }

    _attrModified(name, value, oldValue) {
      super._attrModified(name, value, oldValue);

      if (name === "rel" && this._relList !== undefined) {
        this._relList.attrModified();
      }
    }

  }

  mixin(HTMLAnchorElementImpl.prototype, HTMLHyperlinkElementUtilsImpl.prototype);
  exports = {
    implementation: HTMLAnchorElementImpl
  };
  return exports;
}