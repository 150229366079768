import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _documentBaseUrlDew } from "../helpers/document-base-url.js";
import * as _whatwgUrl2 from "whatwg-url";

var _whatwgUrl = "default" in _whatwgUrl2 ? _whatwgUrl2.default : _whatwgUrl2;

import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _HTMLScriptElementImplDew } from "../nodes/HTMLScriptElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const parseURLToResultingURLRecord_helpers_document_base_url = _documentBaseUrlDew().parseURLToResultingURLRecord;

  const serializeURLwhatwg_url = _whatwgUrl.serializeURL;

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const HTMLElement = _HTMLElementDew();

  const interfaceName = "HTMLScriptElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLScriptElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLScriptElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLScriptElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      get src() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get src' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "src");

          if (value === null) {
            return "";
          }

          const urlRecord = parseURLToResultingURLRecord_helpers_document_base_url(value, esValue[implSymbol]._ownerDocument);

          if (urlRecord !== null) {
            return serializeURLwhatwg_url(urlRecord);
          }

          return conversions.USVString(value);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set src(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set src' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'src' property on 'HTMLScriptElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "src", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get type() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get type' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "type");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set type(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set type' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'type' property on 'HTMLScriptElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "type", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get defer() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get defer' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "defer");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set defer(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set defer' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'defer' property on 'HTMLScriptElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "defer", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "defer");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get crossOrigin() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get crossOrigin' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "crossorigin");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set crossOrigin(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set crossOrigin' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        if (V === null || V === undefined) {
          V = null;
        } else {
          V = conversions["DOMString"](V, {
            context: "Failed to set the 'crossOrigin' property on 'HTMLScriptElement': The provided value",
            globals: globalObject
          });
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "crossorigin", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get text() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get text' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["text"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set text(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set text' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'text' property on 'HTMLScriptElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["text"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get charset() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get charset' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "charset");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set charset(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set charset' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'charset' property on 'HTMLScriptElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "charset", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get event() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get event' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "event");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set event(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set event' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'event' property on 'HTMLScriptElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "event", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get htmlFor() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get htmlFor' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "for");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set htmlFor(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set htmlFor' called on an object that is not a valid instance of HTMLScriptElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'htmlFor' property on 'HTMLScriptElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "for", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

    }

    Object.defineProperties(HTMLScriptElement.prototype, {
      src: {
        enumerable: true
      },
      type: {
        enumerable: true
      },
      defer: {
        enumerable: true
      },
      crossOrigin: {
        enumerable: true
      },
      text: {
        enumerable: true
      },
      charset: {
        enumerable: true
      },
      event: {
        enumerable: true
      },
      htmlFor: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLScriptElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLScriptElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLScriptElement
    });
  };

  const Impl = _HTMLScriptElementImplDew();

  return exports;
}