var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  // https://infra.spec.whatwg.org/#parse-json-from-bytes
  exports.parseJSONFromBytes = bytes => {
    // https://encoding.spec.whatwg.org/#utf-8-decode
    if (bytes[0] === 239 && bytes[1] === 187 && bytes[2] === 191) {
      bytes = bytes.subarray(3);
    }

    const jsonText = bytes.toString("utf-8");
    return JSON.parse(jsonText);
  };

  return exports;
}