var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports.implementation = class NavigatorOnLineImpl {
    get onLine() {
      return true;
    }

  };
  return exports;
}