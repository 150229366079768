import * as _cssom2 from "cssom";

var _cssom = "default" in _cssom2 ? _cssom2.default : _cssom2;

import * as _whatwgEncoding2 from "whatwg-encoding";

var _whatwgEncoding = "default" in _whatwgEncoding2 ? _whatwgEncoding2.default : _whatwgEncoding2;

import * as _whatwgUrl2 from "whatwg-url";

var _whatwgUrl = "default" in _whatwgUrl2 ? _whatwgUrl2.default : _whatwgUrl2;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const cssom = _cssom;
  const whatwgEncoding = _whatwgEncoding;
  const whatwgURL = _whatwgUrl; // TODO: this should really implement https://html.spec.whatwg.org/multipage/links.html#link-type-stylesheet
  // It (and the things it calls) is nowhere close right now.

  exports.fetchStylesheet = (elementImpl, urlString) => {
    const parsedURL = whatwgURL.parseURL(urlString);
    return fetchStylesheetInternal(elementImpl, urlString, parsedURL);
  }; // https://drafts.csswg.org/cssom/#remove-a-css-style-sheet


  exports.removeStylesheet = (sheet, elementImpl) => {
    const {
      styleSheets
    } = elementImpl._ownerDocument;

    styleSheets._remove(sheet); // Remove the association explicitly; in the spec it's implicit so this step doesn't exist.


    elementImpl.sheet = null; // TODO: "Set the CSS style sheet’s parent CSS style sheet, owner node and owner CSS rule to null."
    // Probably when we have a real CSSOM implementation.
  }; // https://drafts.csswg.org/cssom/#create-a-css-style-sheet kinda:
  // - Parsing failures are not handled gracefully like they should be
  // - The import rules stuff seems out of place, and probably should affect the load event...


  exports.createStylesheet = (sheetText, elementImpl, baseURL) => {
    let sheet;

    try {
      sheet = cssom.parse(sheetText);
    } catch (e) {
      if (elementImpl._ownerDocument._defaultView) {
        const error = new Error("Could not parse CSS stylesheet");
        error.detail = sheetText;
        error.type = "css parsing";

        elementImpl._ownerDocument._defaultView._virtualConsole.emit("jsdomError", error);
      }

      return;
    }

    scanForImportRules(elementImpl, sheet.cssRules, baseURL);
    addStylesheet(sheet, elementImpl);
  }; // https://drafts.csswg.org/cssom/#add-a-css-style-sheet


  function addStylesheet(sheet, elementImpl) {
    elementImpl._ownerDocument.styleSheets._add(sheet); // Set the association explicitly; in the spec it's implicit.


    elementImpl.sheet = sheet; // TODO: title and disabled stuff
  }

  function fetchStylesheetInternal(elementImpl, urlString, parsedURL) {
    const document = elementImpl._ownerDocument;
    let defaultEncoding = document._encoding;
    const resourceLoader = document._resourceLoader;

    if (elementImpl.localName === "link" && elementImpl.hasAttributeNS(null, "charset")) {
      defaultEncoding = whatwgEncoding.labelToName(elementImpl.getAttributeNS(null, "charset"));
    }

    function onStylesheetLoad(data) {
      const css = whatwgEncoding.decode(data, defaultEncoding); // TODO: MIME type checking?

      if (elementImpl.sheet) {
        exports.removeStylesheet(elementImpl.sheet, elementImpl);
      }

      exports.createStylesheet(css, elementImpl, parsedURL);
    }

    resourceLoader.fetch(urlString, {
      element: elementImpl,
      onLoad: onStylesheetLoad
    });
  } // TODO this is actually really messed up and overwrites the sheet on elementImpl
  // Tracking in https://github.com/jsdom/jsdom/issues/2124


  function scanForImportRules(elementImpl, cssRules, baseURL) {
    if (!cssRules) {
      return;
    }

    for (let i = 0; i < cssRules.length; ++i) {
      if (cssRules[i].cssRules) {
        // @media rule: keep searching inside it.
        scanForImportRules(elementImpl, cssRules[i].cssRules, baseURL);
      } else if (cssRules[i].href) {
        // @import rule: fetch the resource and evaluate it.
        // See http://dev.w3.org/csswg/cssom/#css-import-rule
        //     If loading of the style sheet fails its cssRules list is simply
        //     empty. I.e. an @import rule always has an associated style sheet.
        const parsed = whatwgURL.parseURL(cssRules[i].href, {
          baseURL
        });

        if (parsed === null) {
          const window = elementImpl._ownerDocument._defaultView;

          if (window) {
            const error = new Error(`Could not parse CSS @import URL ${cssRules[i].href} relative to base URL ` + `"${whatwgURL.serializeURL(baseURL)}"`);
            error.type = "css @import URL parsing";

            window._virtualConsole.emit("jsdomError", error);
          }
        } else {
          fetchStylesheetInternal(elementImpl, whatwgURL.serializeURL(parsed), parsed);
        }
      }
    }
  }

  return exports;
}