import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _stringsDew } from "../helpers/strings.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _HTMLMarqueeElementImplDew } from "../nodes/HTMLMarqueeElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const parseNonNegativeInteger_helpers_strings = _stringsDew().parseNonNegativeInteger;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const HTMLElement = _HTMLElementDew();

  const interfaceName = "HTMLMarqueeElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLMarqueeElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLMarqueeElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLMarqueeElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      get behavior() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get behavior' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "behavior");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set behavior(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set behavior' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'behavior' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "behavior", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get bgColor() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get bgColor' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "bgcolor");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set bgColor(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set bgColor' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'bgColor' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "bgcolor", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get direction() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get direction' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "direction");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set direction(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set direction' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'direction' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "direction", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get height() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get height' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "height");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set height(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set height' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'height' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "height", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get hspace() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get hspace' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "hspace");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set hspace(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set hspace' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'hspace' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "hspace", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get scrollAmount() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get scrollAmount' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "scrollamount");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set scrollAmount(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set scrollAmount' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'scrollAmount' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "scrollamount", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get scrollDelay() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get scrollDelay' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "scrolldelay");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set scrollDelay(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set scrollDelay' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'scrollDelay' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "scrolldelay", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get trueSpeed() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get trueSpeed' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "truespeed");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set trueSpeed(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set trueSpeed' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'trueSpeed' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "truespeed", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "truespeed");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get vspace() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get vspace' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "vspace");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set vspace(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set vspace' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'vspace' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "vspace", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get width() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get width' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "width");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set width(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set width' called on an object that is not a valid instance of HTMLMarqueeElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'width' property on 'HTMLMarqueeElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "width", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

    }

    Object.defineProperties(HTMLMarqueeElement.prototype, {
      behavior: {
        enumerable: true
      },
      bgColor: {
        enumerable: true
      },
      direction: {
        enumerable: true
      },
      height: {
        enumerable: true
      },
      hspace: {
        enumerable: true
      },
      scrollAmount: {
        enumerable: true
      },
      scrollDelay: {
        enumerable: true
      },
      trueSpeed: {
        enumerable: true
      },
      vspace: {
        enumerable: true
      },
      width: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLMarqueeElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLMarqueeElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLMarqueeElement
    });
  };

  const Impl = _HTMLMarqueeElementImplDew();

  return exports;
}