import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  class HTMLOListElementImpl extends HTMLElementImpl {
    get start() {
      const value = parseInt(this.getAttributeNS(null, "start"));

      if (!isNaN(value)) {
        return value;
      }

      return 1;
    }

    set start(value) {
      this.setAttributeNS(null, "start", value);
    }

  }

  exports = {
    implementation: HTMLOListElementImpl
  };
  return exports;
}