import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _HTMLAreaElementImplDew } from "../nodes/HTMLAreaElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const HTMLElement = _HTMLElementDew();

  const interfaceName = "HTMLAreaElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLAreaElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLAreaElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLAreaElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      get alt() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get alt' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "alt");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set alt(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set alt' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'alt' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "alt", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get coords() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get coords' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "coords");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set coords(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set coords' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'coords' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "coords", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get shape() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get shape' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "shape");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set shape(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set shape' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'shape' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "shape", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get target() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get target' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "target");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set target(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set target' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'target' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "target", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get rel() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get rel' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "rel");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set rel(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set rel' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'rel' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "rel", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get relList() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get relList' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        return utils.getSameObject(this, "relList", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["relList"]);
        });
      }

      set relList(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set relList' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        const Q = esValue["relList"];

        if (!utils.isObject(Q)) {
          throw new globalObject.TypeError("Property 'relList' is not an object");
        }

        Reflect.set(Q, "value", V);
      }

      get noHref() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get noHref' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "nohref");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set noHref(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set noHref' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'noHref' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "nohref", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "nohref");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get href() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get href' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["href"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set href(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set href' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'href' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["href"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      toString() {
        const esValue = this;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'toString' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["href"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get origin() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get origin' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        return esValue[implSymbol]["origin"];
      }

      get protocol() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get protocol' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["protocol"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set protocol(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set protocol' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'protocol' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["protocol"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get username() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get username' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["username"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set username(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set username' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'username' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["username"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get password() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get password' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["password"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set password(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set password' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'password' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["password"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get host() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get host' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["host"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set host(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set host' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'host' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["host"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get hostname() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get hostname' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["hostname"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set hostname(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set hostname' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'hostname' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["hostname"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get port() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get port' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["port"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set port(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set port' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'port' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["port"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get pathname() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get pathname' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["pathname"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set pathname(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set pathname' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'pathname' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["pathname"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get search() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get search' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["search"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set search(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set search' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'search' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["search"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get hash() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get hash' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["hash"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set hash(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set hash' called on an object that is not a valid instance of HTMLAreaElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'hash' property on 'HTMLAreaElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["hash"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

    }

    Object.defineProperties(HTMLAreaElement.prototype, {
      alt: {
        enumerable: true
      },
      coords: {
        enumerable: true
      },
      shape: {
        enumerable: true
      },
      target: {
        enumerable: true
      },
      rel: {
        enumerable: true
      },
      relList: {
        enumerable: true
      },
      noHref: {
        enumerable: true
      },
      href: {
        enumerable: true
      },
      toString: {
        enumerable: true
      },
      origin: {
        enumerable: true
      },
      protocol: {
        enumerable: true
      },
      username: {
        enumerable: true
      },
      password: {
        enumerable: true
      },
      host: {
        enumerable: true
      },
      hostname: {
        enumerable: true
      },
      port: {
        enumerable: true
      },
      pathname: {
        enumerable: true
      },
      search: {
        enumerable: true
      },
      hash: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLAreaElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLAreaElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLAreaElement
    });
  };

  const Impl = _HTMLAreaElementImplDew();

  return exports;
}