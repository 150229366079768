import { dew as _namedPropertiesTrackerDew } from "../named-properties-tracker";
import { dew as _nodeTypeDew } from "./node-type";
import { dew as _HTMLCollectionDew } from "./generated/HTMLCollection";
import { dew as _utilsDew } from "../utils";
import { dew as _utils2Dew } from "./generated/utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const hasOwnProp = Object.prototype.hasOwnProperty;

  const namedPropertiesTracker = _namedPropertiesTrackerDew();

  const NODE_TYPE = _nodeTypeDew();

  const HTMLCollection = _HTMLCollectionDew();

  const {
    treeOrderSorter
  } = _utilsDew();

  const idlUtils = _utils2Dew();

  function isNamedPropertyElement(element) {
    // (for the name attribute)
    // use hasOwnProperty to make sure contentWindow comes from the prototype,
    // and is not set directly on the node by a script.
    if ("contentWindow" in element && !hasOwnProp.call(element, "contentWindow")) {
      return true;
    }

    switch (element.nodeName) {
      case "A":
      case "AREA":
      case "EMBED":
      case "FORM":
      case "FRAMESET":
      case "IMG":
      case "OBJECT":
        return true;

      default:
        return false;
    }
  }

  function namedPropertyResolver(window, name, values) {
    function getResult() {
      const results = [];

      for (const node of values().keys()) {
        if (node.nodeType !== NODE_TYPE.ELEMENT_NODE) {
          continue;
        }

        if (node.getAttributeNS(null, "id") === name) {
          results.push(node);
        } else if (node.getAttributeNS(null, "name") === name && isNamedPropertyElement(node)) {
          results.push(node);
        }
      }

      results.sort(treeOrderSorter);
      return results;
    }

    const document = window._document;
    const objects = HTMLCollection.create(window, [], {
      element: idlUtils.implForWrapper(document.documentElement),
      query: getResult
    });
    const {
      length
    } = objects;

    for (let i = 0; i < length; ++i) {
      const node = objects[i];

      if ("contentWindow" in node && !hasOwnProp.call(node, "contentWindow") && node.getAttributeNS(null, "name") === name) {
        return node.contentWindow;
      }
    }

    if (length === 0) {
      return undefined;
    }

    if (length === 1) {
      return objects[0];
    }

    return objects;
  }

  exports.initializeWindow = function (window, windowProxy) {
    namedPropertiesTracker.create(window, windowProxy, namedPropertyResolver.bind(null));
  };

  exports.elementAttributeModified = function (element, name, value, oldValue) {
    if (!element._attached) {
      return;
    }

    const useName = isNamedPropertyElement(element);

    if (name === "id" || name === "name" && useName) {
      const tracker = namedPropertiesTracker.get(element._ownerDocument._global); // (tracker will be null if the document has no Window)

      if (tracker) {
        if (name === "id" && (!useName || element.getAttributeNS(null, "name") !== oldValue)) {
          tracker.untrack(oldValue, element);
        }

        if (name === "name" && element.getAttributeNS(null, "id") !== oldValue) {
          tracker.untrack(oldValue, element);
        }

        tracker.track(value, element);
      }
    }
  };

  exports.nodeAttachedToDocument = function (node) {
    if (node.nodeType !== NODE_TYPE.ELEMENT_NODE) {
      return;
    }

    const tracker = namedPropertiesTracker.get(node._ownerDocument._global);

    if (!tracker) {
      return;
    }

    tracker.track(node.getAttributeNS(null, "id"), node);

    if (isNamedPropertyElement(node)) {
      tracker.track(node.getAttributeNS(null, "name"), node);
    }
  };

  exports.nodeDetachedFromDocument = function (node) {
    if (node.nodeType !== NODE_TYPE.ELEMENT_NODE) {
      return;
    }

    const tracker = namedPropertiesTracker.get(node._ownerDocument._global);

    if (!tracker) {
      return;
    }

    tracker.untrack(node.getAttributeNS(null, "id"), node);

    if (isNamedPropertyElement(node)) {
      tracker.untrack(node.getAttributeNS(null, "name"), node);
    }
  };

  return exports;
}