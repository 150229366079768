import * as _w3cXmlserializer2 from "w3c-xmlserializer";

var _w3cXmlserializer = "default" in _w3cXmlserializer2 ? _w3cXmlserializer2.default : _w3cXmlserializer2;

import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

import { dew as _utilsDew } from "../generated/utils";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const serialize = _w3cXmlserializer;
  const DOMException = _webidl2jsWrapper;

  const utils = _utilsDew();

  exports.implementation = class XMLSerializerImpl {
    constructor(globalObject) {
      this._globalObject = globalObject;
    }

    serializeToString(root) {
      try {
        return serialize(utils.wrapperForImpl(root), {
          requireWellFormed: false
        });
      } catch (e) {
        throw DOMException.create(this._globalObject, [e.message, "InvalidStateError"]);
      }
    }

  };
  return exports;
}