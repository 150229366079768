import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _HTMLTableRowElementImplDew } from "../nodes/HTMLTableRowElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const HTMLElement = _HTMLElementDew();

  const interfaceName = "HTMLTableRowElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLTableRowElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLTableRowElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLTableRowElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      insertCell() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'insertCell' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg !== undefined) {
            curArg = conversions["long"](curArg, {
              context: "Failed to execute 'insertCell' on 'HTMLTableRowElement': parameter 1",
              globals: globalObject
            });
          } else {
            curArg = -1;
          }

          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].insertCell(...args));
      }

      deleteCell(index) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'deleteCell' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'deleteCell' on 'HTMLTableRowElement': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["long"](curArg, {
            context: "Failed to execute 'deleteCell' on 'HTMLTableRowElement': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].deleteCell(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get rowIndex() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get rowIndex' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        return esValue[implSymbol]["rowIndex"];
      }

      get sectionRowIndex() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get sectionRowIndex' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        return esValue[implSymbol]["sectionRowIndex"];
      }

      get cells() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get cells' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        return utils.getSameObject(this, "cells", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["cells"]);
        });
      }

      get align() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get align' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "align");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set align(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set align' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'align' property on 'HTMLTableRowElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "align", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get ch() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get ch' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "char");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set ch(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set ch' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'ch' property on 'HTMLTableRowElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "char", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get chOff() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get chOff' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "charoff");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set chOff(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set chOff' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'chOff' property on 'HTMLTableRowElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "charoff", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get vAlign() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get vAlign' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "valign");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set vAlign(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set vAlign' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'vAlign' property on 'HTMLTableRowElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "valign", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get bgColor() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get bgColor' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "bgcolor");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set bgColor(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set bgColor' called on an object that is not a valid instance of HTMLTableRowElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'bgColor' property on 'HTMLTableRowElement': The provided value",
          globals: globalObject,
          treatNullAsEmptyString: true
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "bgcolor", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

    }

    Object.defineProperties(HTMLTableRowElement.prototype, {
      insertCell: {
        enumerable: true
      },
      deleteCell: {
        enumerable: true
      },
      rowIndex: {
        enumerable: true
      },
      sectionRowIndex: {
        enumerable: true
      },
      cells: {
        enumerable: true
      },
      align: {
        enumerable: true
      },
      ch: {
        enumerable: true
      },
      chOff: {
        enumerable: true
      },
      vAlign: {
        enumerable: true
      },
      bgColor: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLTableRowElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLTableRowElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLTableRowElement
    });
  };

  const Impl = _HTMLTableRowElementImplDew();

  return exports;
}