import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _ShadowRootModeDew } from "./ShadowRootMode.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const ShadowRootMode = _ShadowRootModeDew();

  exports._convertInherit = (globalObject, obj, ret, {
    context = "The provided value"
  } = {}) => {
    {
      const key = "mode";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = ShadowRootMode.convert(globalObject, value, {
          context: context + " has member 'mode' that"
        });
        ret[key] = value;
      } else {
        throw new globalObject.TypeError("mode is required in 'ShadowRootInit'");
      }
    }
  };

  exports.convert = (globalObject, obj, {
    context = "The provided value"
  } = {}) => {
    if (obj !== undefined && typeof obj !== "object" && typeof obj !== "function") {
      throw new globalObject.TypeError(`${context} is not an object.`);
    }

    const ret = Object.create(null);

    exports._convertInherit(globalObject, obj, ret, {
      context
    });

    return ret;
  };

  return exports;
}