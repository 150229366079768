import { dew as _EventImplDew } from "./Event-impl";
import { dew as _PageTransitionEventInitDew } from "../generated/PageTransitionEventInit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const EventImpl = _EventImplDew().implementation;

  const PageTransitionEventInit = _PageTransitionEventInitDew(); // https://html.spec.whatwg.org/multipage/browsing-the-web.html#pagetransitionevent


  class PageTransitionEventImpl extends EventImpl {
    initPageTransitionEvent(type, bubbles, cancelable, persisted) {
      if (this._dispatchFlag) {
        return;
      }

      this.initEvent(type, bubbles, cancelable);
      this.persisted = persisted;
    }

  }

  PageTransitionEventImpl.defaultInit = PageTransitionEventInit.convert(undefined, undefined);
  exports.implementation = PageTransitionEventImpl;
  return exports;
}