import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _AttrDew } from "./Attr.js";
import { dew as _ShadowRootInitDew } from "./ShadowRootInit.js";
import { dew as _NodeDew } from "./Node.js";
import { dew as _ElementImplDew } from "../nodes/Element-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const Attr = _AttrDew();

  const ShadowRootInit = _ShadowRootInitDew();

  const Node = _NodeDew();

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;
  const interfaceName = "Element";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'Element'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["Element"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    Node._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class Element extends globalObject.Node {
      constructor() {
        throw new globalObject.TypeError("Illegal constructor");
      }

      hasAttributes() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'hasAttributes' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol].hasAttributes();
      }

      getAttributeNames() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getAttributeNames' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol].getAttributeNames());
      }

      getAttribute(qualifiedName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getAttribute' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'getAttribute' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getAttribute' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].getAttribute(...args);
      }

      getAttributeNS(namespace, localName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getAttributeNS' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'getAttributeNS' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg === null || curArg === undefined) {
            curArg = null;
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'getAttributeNS' on 'Element': parameter 1",
              globals: globalObject
            });
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getAttributeNS' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].getAttributeNS(...args);
      }

      setAttribute(qualifiedName, value) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'setAttribute' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'setAttribute' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'setAttribute' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'setAttribute' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].setAttribute(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      setAttributeNS(namespace, qualifiedName, value) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'setAttributeNS' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 3) {
          throw new globalObject.TypeError(`Failed to execute 'setAttributeNS' on 'Element': 3 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg === null || curArg === undefined) {
            curArg = null;
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'setAttributeNS' on 'Element': parameter 1",
              globals: globalObject
            });
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'setAttributeNS' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[2];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'setAttributeNS' on 'Element': parameter 3",
            globals: globalObject
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].setAttributeNS(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      removeAttribute(qualifiedName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'removeAttribute' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'removeAttribute' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'removeAttribute' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].removeAttribute(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      removeAttributeNS(namespace, localName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'removeAttributeNS' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'removeAttributeNS' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg === null || curArg === undefined) {
            curArg = null;
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'removeAttributeNS' on 'Element': parameter 1",
              globals: globalObject
            });
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'removeAttributeNS' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].removeAttributeNS(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      toggleAttribute(qualifiedName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'toggleAttribute' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'toggleAttribute' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'toggleAttribute' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];

          if (curArg !== undefined) {
            curArg = conversions["boolean"](curArg, {
              context: "Failed to execute 'toggleAttribute' on 'Element': parameter 2",
              globals: globalObject
            });
          }

          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].toggleAttribute(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      hasAttribute(qualifiedName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'hasAttribute' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'hasAttribute' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'hasAttribute' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].hasAttribute(...args);
      }

      hasAttributeNS(namespace, localName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'hasAttributeNS' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'hasAttributeNS' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg === null || curArg === undefined) {
            curArg = null;
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'hasAttributeNS' on 'Element': parameter 1",
              globals: globalObject
            });
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'hasAttributeNS' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].hasAttributeNS(...args);
      }

      getAttributeNode(qualifiedName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getAttributeNode' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'getAttributeNode' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getAttributeNode' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].getAttributeNode(...args));
      }

      getAttributeNodeNS(namespace, localName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getAttributeNodeNS' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'getAttributeNodeNS' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg === null || curArg === undefined) {
            curArg = null;
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'getAttributeNodeNS' on 'Element': parameter 1",
              globals: globalObject
            });
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getAttributeNodeNS' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].getAttributeNodeNS(...args));
      }

      setAttributeNode(attr) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'setAttributeNode' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'setAttributeNode' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = Attr.convert(globalObject, curArg, {
            context: "Failed to execute 'setAttributeNode' on 'Element': parameter 1"
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return utils.tryWrapperForImpl(esValue[implSymbol].setAttributeNode(...args));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      setAttributeNodeNS(attr) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'setAttributeNodeNS' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'setAttributeNodeNS' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = Attr.convert(globalObject, curArg, {
            context: "Failed to execute 'setAttributeNodeNS' on 'Element': parameter 1"
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return utils.tryWrapperForImpl(esValue[implSymbol].setAttributeNodeNS(...args));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      removeAttributeNode(attr) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'removeAttributeNode' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'removeAttributeNode' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = Attr.convert(globalObject, curArg, {
            context: "Failed to execute 'removeAttributeNode' on 'Element': parameter 1"
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return utils.tryWrapperForImpl(esValue[implSymbol].removeAttributeNode(...args));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      attachShadow(init) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'attachShadow' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'attachShadow' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = ShadowRootInit.convert(globalObject, curArg, {
            context: "Failed to execute 'attachShadow' on 'Element': parameter 1"
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].attachShadow(...args));
      }

      closest(selectors) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'closest' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'closest' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'closest' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].closest(...args));
      }

      matches(selectors) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'matches' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'matches' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'matches' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].matches(...args);
      }

      webkitMatchesSelector(selectors) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'webkitMatchesSelector' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'webkitMatchesSelector' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'webkitMatchesSelector' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].webkitMatchesSelector(...args);
      }

      getElementsByTagName(qualifiedName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getElementsByTagName' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'getElementsByTagName' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getElementsByTagName' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].getElementsByTagName(...args));
      }

      getElementsByTagNameNS(namespace, localName) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getElementsByTagNameNS' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'getElementsByTagNameNS' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg === null || curArg === undefined) {
            curArg = null;
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'getElementsByTagNameNS' on 'Element': parameter 1",
              globals: globalObject
            });
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getElementsByTagNameNS' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].getElementsByTagNameNS(...args));
      }

      getElementsByClassName(classNames) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getElementsByClassName' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'getElementsByClassName' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getElementsByClassName' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].getElementsByClassName(...args));
      }

      insertAdjacentElement(where, element) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'insertAdjacentElement' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'insertAdjacentElement' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'insertAdjacentElement' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = exports.convert(globalObject, curArg, {
            context: "Failed to execute 'insertAdjacentElement' on 'Element': parameter 2"
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return utils.tryWrapperForImpl(esValue[implSymbol].insertAdjacentElement(...args));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      insertAdjacentText(where, data) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'insertAdjacentText' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'insertAdjacentText' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'insertAdjacentText' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'insertAdjacentText' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].insertAdjacentText(...args);
      }

      insertAdjacentHTML(position, text) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'insertAdjacentHTML' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'insertAdjacentHTML' on 'Element': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'insertAdjacentHTML' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'insertAdjacentHTML' on 'Element': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].insertAdjacentHTML(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      getClientRects() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getClientRects' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol].getClientRects());
      }

      getBoundingClientRect() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getBoundingClientRect' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol].getBoundingClientRect());
      }

      before() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'before' called on an object that is not a valid instance of Element.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'before' on 'Element': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].before(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      after() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'after' called on an object that is not a valid instance of Element.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'after' on 'Element': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].after(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      replaceWith() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'replaceWith' called on an object that is not a valid instance of Element.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'replaceWith' on 'Element': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].replaceWith(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      remove() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'remove' called on an object that is not a valid instance of Element.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].remove();
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      prepend() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'prepend' called on an object that is not a valid instance of Element.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'prepend' on 'Element': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].prepend(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      append() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'append' called on an object that is not a valid instance of Element.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'append' on 'Element': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].append(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      replaceChildren() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'replaceChildren' called on an object that is not a valid instance of Element.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];

          if (Node.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'replaceChildren' on 'Element': parameter " + (i + 1),
              globals: globalObject
            });
          }

          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].replaceChildren(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      querySelector(selectors) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'querySelector' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'querySelector' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'querySelector' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].querySelector(...args));
      }

      querySelectorAll(selectors) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'querySelectorAll' called on an object that is not a valid instance of Element.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'querySelectorAll' on 'Element': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'querySelectorAll' on 'Element': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].querySelectorAll(...args));
      }

      get namespaceURI() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get namespaceURI' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["namespaceURI"];
      }

      get prefix() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get prefix' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["prefix"];
      }

      get localName() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get localName' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["localName"];
      }

      get tagName() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get tagName' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["tagName"];
      }

      get id() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get id' called on an object that is not a valid instance of Element.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "id");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set id(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set id' called on an object that is not a valid instance of Element.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'id' property on 'Element': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "id", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get className() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get className' called on an object that is not a valid instance of Element.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "class");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set className(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set className' called on an object that is not a valid instance of Element.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'className' property on 'Element': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "class", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get classList() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get classList' called on an object that is not a valid instance of Element.");
        }

        return utils.getSameObject(this, "classList", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["classList"]);
        });
      }

      set classList(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set classList' called on an object that is not a valid instance of Element.");
        }

        const Q = esValue["classList"];

        if (!utils.isObject(Q)) {
          throw new globalObject.TypeError("Property 'classList' is not an object");
        }

        Reflect.set(Q, "value", V);
      }

      get slot() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get slot' called on an object that is not a valid instance of Element.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "slot");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set slot(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set slot' called on an object that is not a valid instance of Element.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'slot' property on 'Element': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "slot", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get attributes() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get attributes' called on an object that is not a valid instance of Element.");
        }

        return utils.getSameObject(this, "attributes", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["attributes"]);
        });
      }

      get shadowRoot() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get shadowRoot' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["shadowRoot"]);
      }

      get outerHTML() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get outerHTML' called on an object that is not a valid instance of Element.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["outerHTML"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set outerHTML(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set outerHTML' called on an object that is not a valid instance of Element.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'outerHTML' property on 'Element': The provided value",
          globals: globalObject,
          treatNullAsEmptyString: true
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["outerHTML"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get scrollTop() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get scrollTop' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["scrollTop"];
      }

      set scrollTop(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set scrollTop' called on an object that is not a valid instance of Element.");
        }

        V = conversions["unrestricted double"](V, {
          context: "Failed to set the 'scrollTop' property on 'Element': The provided value",
          globals: globalObject
        });
        esValue[implSymbol]["scrollTop"] = V;
      }

      get scrollLeft() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get scrollLeft' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["scrollLeft"];
      }

      set scrollLeft(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set scrollLeft' called on an object that is not a valid instance of Element.");
        }

        V = conversions["unrestricted double"](V, {
          context: "Failed to set the 'scrollLeft' property on 'Element': The provided value",
          globals: globalObject
        });
        esValue[implSymbol]["scrollLeft"] = V;
      }

      get scrollWidth() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get scrollWidth' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["scrollWidth"];
      }

      get scrollHeight() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get scrollHeight' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["scrollHeight"];
      }

      get clientTop() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get clientTop' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["clientTop"];
      }

      get clientLeft() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get clientLeft' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["clientLeft"];
      }

      get clientWidth() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get clientWidth' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["clientWidth"];
      }

      get clientHeight() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get clientHeight' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["clientHeight"];
      }

      get innerHTML() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get innerHTML' called on an object that is not a valid instance of Element.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["innerHTML"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set innerHTML(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set innerHTML' called on an object that is not a valid instance of Element.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'innerHTML' property on 'Element': The provided value",
          globals: globalObject,
          treatNullAsEmptyString: true
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["innerHTML"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get previousElementSibling() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get previousElementSibling' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["previousElementSibling"]);
      }

      get nextElementSibling() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get nextElementSibling' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["nextElementSibling"]);
      }

      get children() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get children' called on an object that is not a valid instance of Element.");
        }

        return utils.getSameObject(this, "children", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["children"]);
        });
      }

      get firstElementChild() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get firstElementChild' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["firstElementChild"]);
      }

      get lastElementChild() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get lastElementChild' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["lastElementChild"]);
      }

      get childElementCount() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get childElementCount' called on an object that is not a valid instance of Element.");
        }

        return esValue[implSymbol]["childElementCount"];
      }

      get assignedSlot() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get assignedSlot' called on an object that is not a valid instance of Element.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["assignedSlot"]);
      }

    }

    Object.defineProperties(Element.prototype, {
      hasAttributes: {
        enumerable: true
      },
      getAttributeNames: {
        enumerable: true
      },
      getAttribute: {
        enumerable: true
      },
      getAttributeNS: {
        enumerable: true
      },
      setAttribute: {
        enumerable: true
      },
      setAttributeNS: {
        enumerable: true
      },
      removeAttribute: {
        enumerable: true
      },
      removeAttributeNS: {
        enumerable: true
      },
      toggleAttribute: {
        enumerable: true
      },
      hasAttribute: {
        enumerable: true
      },
      hasAttributeNS: {
        enumerable: true
      },
      getAttributeNode: {
        enumerable: true
      },
      getAttributeNodeNS: {
        enumerable: true
      },
      setAttributeNode: {
        enumerable: true
      },
      setAttributeNodeNS: {
        enumerable: true
      },
      removeAttributeNode: {
        enumerable: true
      },
      attachShadow: {
        enumerable: true
      },
      closest: {
        enumerable: true
      },
      matches: {
        enumerable: true
      },
      webkitMatchesSelector: {
        enumerable: true
      },
      getElementsByTagName: {
        enumerable: true
      },
      getElementsByTagNameNS: {
        enumerable: true
      },
      getElementsByClassName: {
        enumerable: true
      },
      insertAdjacentElement: {
        enumerable: true
      },
      insertAdjacentText: {
        enumerable: true
      },
      insertAdjacentHTML: {
        enumerable: true
      },
      getClientRects: {
        enumerable: true
      },
      getBoundingClientRect: {
        enumerable: true
      },
      before: {
        enumerable: true
      },
      after: {
        enumerable: true
      },
      replaceWith: {
        enumerable: true
      },
      remove: {
        enumerable: true
      },
      prepend: {
        enumerable: true
      },
      append: {
        enumerable: true
      },
      replaceChildren: {
        enumerable: true
      },
      querySelector: {
        enumerable: true
      },
      querySelectorAll: {
        enumerable: true
      },
      namespaceURI: {
        enumerable: true
      },
      prefix: {
        enumerable: true
      },
      localName: {
        enumerable: true
      },
      tagName: {
        enumerable: true
      },
      id: {
        enumerable: true
      },
      className: {
        enumerable: true
      },
      classList: {
        enumerable: true
      },
      slot: {
        enumerable: true
      },
      attributes: {
        enumerable: true
      },
      shadowRoot: {
        enumerable: true
      },
      outerHTML: {
        enumerable: true
      },
      scrollTop: {
        enumerable: true
      },
      scrollLeft: {
        enumerable: true
      },
      scrollWidth: {
        enumerable: true
      },
      scrollHeight: {
        enumerable: true
      },
      clientTop: {
        enumerable: true
      },
      clientLeft: {
        enumerable: true
      },
      clientWidth: {
        enumerable: true
      },
      clientHeight: {
        enumerable: true
      },
      innerHTML: {
        enumerable: true
      },
      previousElementSibling: {
        enumerable: true
      },
      nextElementSibling: {
        enumerable: true
      },
      children: {
        enumerable: true
      },
      firstElementChild: {
        enumerable: true
      },
      lastElementChild: {
        enumerable: true
      },
      childElementCount: {
        enumerable: true
      },
      assignedSlot: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "Element",
        configurable: true
      },
      [Symbol.unscopables]: {
        value: {
          slot: true,
          before: true,
          after: true,
          replaceWith: true,
          remove: true,
          prepend: true,
          append: true,
          replaceChildren: true,
          __proto__: null
        },
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = Element;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: Element
    });
  };

  const Impl = _ElementImplDew();

  return exports;
}