import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

import { dew as _nodeTypeDew } from "../node-type";
import { dew as _AbstractRangeImplDew } from "./AbstractRange-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const DOMException = _webidl2jsWrapper;

  const NODE_TYPE = _nodeTypeDew();

  const AbstractRangeImpl = _AbstractRangeImplDew().implementation; // https://dom.spec.whatwg.org/#staticrange


  class StaticRangeImpl extends AbstractRangeImpl {
    // https://dom.spec.whatwg.org/#dom-staticrange-staticrange
    constructor(globalObject, args) {
      const {
        startContainer,
        startOffset,
        endContainer,
        endOffset
      } = args[0];

      if (startContainer.nodeType === NODE_TYPE.DOCUMENT_TYPE_NODE || startContainer.nodeType === NODE_TYPE.ATTRIBUTE_NODE || endContainer.nodeType === NODE_TYPE.DOCUMENT_TYPE_NODE || endContainer.nodeType === NODE_TYPE.ATTRIBUTE_NODE) {
        throw DOMException.create(globalObject, ["The supplied node is incorrect.", "InvalidNodeTypeError"]);
      }

      super(globalObject, [], {
        start: {
          node: startContainer,
          offset: startOffset
        },
        end: {
          node: endContainer,
          offset: endOffset
        }
      });
    }

  }

  exports = {
    implementation: StaticRangeImpl
  };
  return exports;
}