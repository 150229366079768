import { dew as _MouseEventImplDew } from "./MouseEvent-impl";
import { dew as _WheelEventInitDew } from "../generated/WheelEventInit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const MouseEventImpl = _MouseEventImplDew().implementation;

  const WheelEventInit = _WheelEventInitDew();

  class WheelEventImpl extends MouseEventImpl {}

  WheelEventImpl.defaultInit = WheelEventInit.convert(undefined, undefined);
  exports = {
    implementation: WheelEventImpl
  };
  return exports;
}