import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

import * as _whatwgUrl2 from "whatwg-url";

var _whatwgUrl = "default" in _whatwgUrl2 ? _whatwgUrl2.default : _whatwgUrl2;

import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _internalConstantsDew } from "../helpers/internal-constants";
import { dew as _eventsDew } from "../helpers/events";
import { dew as _formControlsDew } from "../helpers/form-controls";
import { dew as _HTMLFormControlsCollectionDew } from "../generated/HTMLFormControlsCollection";
import { dew as _notImplementedDew } from "../../browser/not-implemented";
import { dew as _documentBaseUrlDew } from "../helpers/document-base-url";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const DOMException = _webidl2jsWrapper;
  const {
    serializeURL
  } = _whatwgUrl;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const {
    domSymbolTree
  } = _internalConstantsDew();

  const {
    fireAnEvent
  } = _eventsDew();

  const {
    formOwner,
    isListed,
    isSubmittable,
    isSubmitButton
  } = _formControlsDew();

  const HTMLFormControlsCollection = _HTMLFormControlsCollectionDew();

  const notImplemented = _notImplementedDew();

  const {
    parseURLToResultingURLRecord
  } = _documentBaseUrlDew();

  const encTypes = new Set(["application/x-www-form-urlencoded", "multipart/form-data", "text/plain"]);
  const methods = new Set(["get", "post", "dialog"]);
  const constraintValidationPositiveResult = Symbol("positive");
  const constraintValidationNegativeResult = Symbol("negative");

  class HTMLFormElementImpl extends HTMLElementImpl {
    _descendantAdded(parent, child) {
      const form = this;

      for (const el of domSymbolTree.treeIterator(child)) {
        if (typeof el._changedFormOwner === "function") {
          el._changedFormOwner(form);
        }
      }

      super._descendantAdded(parent, child);
    }

    _descendantRemoved(parent, child) {
      for (const el of domSymbolTree.treeIterator(child)) {
        if (typeof el._changedFormOwner === "function") {
          el._changedFormOwner(null);
        }
      }

      super._descendantRemoved(parent, child);
    }

    _getElementNodes() {
      return domSymbolTree.treeToArray(this.getRootNode({}), {
        filter: node => {
          if (!isListed(node) || node._localName === "input" && node.type === "image") {
            return false;
          }

          return formOwner(node) === this;
        }
      });
    } // https://html.spec.whatwg.org/multipage/forms.html#dom-form-elements


    get elements() {
      return HTMLFormControlsCollection.createImpl(this._globalObject, [], {
        element: this.getRootNode({}),
        query: () => this._getElementNodes()
      });
    }

    get length() {
      return this.elements.length;
    }

    _doRequestSubmit(submitter) {
      if (!this.isConnected) {
        return;
      }

      this.requestSubmit(submitter);
    }

    submit() {
      if (!fireAnEvent("submit", this, undefined, {
        bubbles: true,
        cancelable: true
      })) {
        return;
      }

      notImplemented("HTMLFormElement.prototype.submit", this._ownerDocument._defaultView);
    }

    requestSubmit(submitter = undefined) {
      if (submitter !== undefined) {
        if (!isSubmitButton(submitter)) {
          throw new TypeError("The specified element is not a submit button");
        }

        if (submitter.form !== this) {
          throw DOMException.create(this._globalObject, ["The specified element is not owned by this form element", "NotFoundError"]);
        }
      } // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#form-submission-algorithm
      // Step 6.3: if form doesn't have the 'novalidate' attribute, validate it and abort submission if form is invalid


      if (!this.hasAttributeNS(null, "novalidate") && !this.reportValidity()) {
        return;
      }

      if (!fireAnEvent("submit", this, undefined, {
        bubbles: true,
        cancelable: true
      })) {
        return;
      }

      notImplemented("HTMLFormElement.prototype.requestSubmit", this._ownerDocument._defaultView);
    }

    _doReset() {
      if (!this.isConnected) {
        return;
      }

      this.reset();
    }

    reset() {
      if (!fireAnEvent("reset", this, undefined, {
        bubbles: true,
        cancelable: true
      })) {
        return;
      }

      for (const el of this.elements) {
        if (typeof el._formReset === "function") {
          el._formReset();
        }
      }
    }

    get method() {
      let method = this.getAttributeNS(null, "method");

      if (method) {
        method = method.toLowerCase();
      }

      if (methods.has(method)) {
        return method;
      }

      return "get";
    }

    set method(V) {
      this.setAttributeNS(null, "method", V);
    }

    get enctype() {
      let type = this.getAttributeNS(null, "enctype");

      if (type) {
        type = type.toLowerCase();
      }

      if (encTypes.has(type)) {
        return type;
      }

      return "application/x-www-form-urlencoded";
    }

    set enctype(V) {
      this.setAttributeNS(null, "enctype", V);
    }

    get action() {
      const attributeValue = this.getAttributeNS(null, "action");

      if (attributeValue === null || attributeValue === "") {
        return this._ownerDocument.URL;
      }

      const urlRecord = parseURLToResultingURLRecord(attributeValue, this._ownerDocument);

      if (urlRecord === null) {
        return attributeValue;
      }

      return serializeURL(urlRecord);
    }

    set action(V) {
      this.setAttributeNS(null, "action", V);
    } // If the checkValidity() method is invoked, the user agent must statically validate the
    // constraints of the form element, and return true if the constraint validation returned
    // a positive result, and false if it returned a negative result.


    checkValidity() {
      return this._staticallyValidateConstraints().result === constraintValidationPositiveResult;
    } // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#interactively-validate-the-constraints


    reportValidity() {
      return this.checkValidity();
    } // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#statically-validate-the-constraints


    _staticallyValidateConstraints() {
      const controls = [];

      for (const el of this.elements) {
        if (el.form === this && isSubmittable(el)) {
          controls.push(el);
        }
      }

      const invalidControls = [];

      for (const control of controls) {
        if (control._isCandidateForConstraintValidation() && !control._satisfiesConstraints()) {
          invalidControls.push(control);
        }
      }

      if (invalidControls.length === 0) {
        return {
          result: constraintValidationPositiveResult
        };
      }

      const unhandledInvalidControls = [];

      for (const invalidControl of invalidControls) {
        const notCancelled = fireAnEvent("invalid", invalidControl, undefined, {
          cancelable: true
        });

        if (notCancelled) {
          unhandledInvalidControls.push(invalidControl);
        }
      }

      return {
        result: constraintValidationNegativeResult,
        unhandledInvalidControls
      };
    }

  }

  exports = {
    implementation: HTMLFormElementImpl
  };
  return exports;
}