import * as _crypto2 from "crypto";

var _crypto = "default" in _crypto2 ? _crypto2.default : _crypto2;

import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const nodeCrypto = _crypto;
  const DOMException = _webidl2jsWrapper; // https://w3c.github.io/webcrypto/#crypto-interface

  class CryptoImpl {
    constructor(globalObject) {
      this._globalObject = globalObject;
    } // https://w3c.github.io/webcrypto/#Crypto-method-getRandomValues


    getRandomValues(array) {
      // Note: this rejects Float32Array, Float64Array and DataView.
      //
      // We perform "instance tests" by comparing `array.constructor.name` so
      // that the tests will be successful across realms.
      const typeName = array.constructor.name;

      if (!(typeName === "Int8Array" || typeName === "Uint8Array" || typeName === "Uint8ClampedArray" || typeName === "Int16Array" || typeName === "Uint16Array" || typeName === "Int32Array" || typeName === "Uint32Array" || typeName === "BigInt64Array" || typeName === "BigUint64Array")) {
        throw DOMException.create(this._globalObject, [`getRandomValues() only accepts integer typed arrays`, "TypeMismatchError"]);
      }

      if (array.byteLength > 65536) {
        throw DOMException.create(this._globalObject, [`getRandomValues() cannot generate more than 65536 bytes of random values; ` + `${array.byteLength} bytes were requested`, "QuotaExceededError"]);
      }

      nodeCrypto.randomFillSync(array);
      return array;
    }

  }

  exports.implementation = CryptoImpl;
  return exports;
}