import { dew as _utilsDew } from "../../utils";
import { dew as _PluginArrayDew } from "../generated/PluginArray";
import { dew as _MimeTypeArrayDew } from "../generated/MimeTypeArray";
import { dew as _NavigatorIDImplDew } from "./NavigatorID-impl";
import { dew as _NavigatorLanguageImplDew } from "./NavigatorLanguage-impl";
import { dew as _NavigatorOnLineImplDew } from "./NavigatorOnLine-impl";
import { dew as _NavigatorCookiesImplDew } from "./NavigatorCookies-impl";
import { dew as _NavigatorPluginsImplDew } from "./NavigatorPlugins-impl";
import { dew as _NavigatorConcurrentHardwareImplDew } from "./NavigatorConcurrentHardware-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    mixin
  } = _utilsDew();

  const PluginArray = _PluginArrayDew();

  const MimeTypeArray = _MimeTypeArrayDew();

  const NavigatorIDImpl = _NavigatorIDImplDew().implementation;

  const NavigatorLanguageImpl = _NavigatorLanguageImplDew().implementation;

  const NavigatorOnLineImpl = _NavigatorOnLineImplDew().implementation;

  const NavigatorCookiesImpl = _NavigatorCookiesImplDew().implementation;

  const NavigatorPluginsImpl = _NavigatorPluginsImplDew().implementation;

  const NavigatorConcurrentHardwareImpl = _NavigatorConcurrentHardwareImplDew().implementation;

  class NavigatorImpl {
    constructor(globalObject, args, privateData) {
      this._globalObject = globalObject;
      this.userAgent = privateData.userAgent;
      this.languages = Object.freeze(["en-US", "en"]);
      this.plugins = PluginArray.create(this._globalObject);
      this.mimeTypes = MimeTypeArray.create(this._globalObject);
    }

  }

  mixin(NavigatorImpl.prototype, NavigatorIDImpl.prototype);
  mixin(NavigatorImpl.prototype, NavigatorLanguageImpl.prototype);
  mixin(NavigatorImpl.prototype, NavigatorOnLineImpl.prototype);
  mixin(NavigatorImpl.prototype, NavigatorCookiesImpl.prototype);
  mixin(NavigatorImpl.prototype, NavigatorPluginsImpl.prototype);
  mixin(NavigatorImpl.prototype, NavigatorConcurrentHardwareImpl.prototype);
  exports.implementation = NavigatorImpl;
  return exports;
}