import { dew as _SVGElementImplDew } from "./SVGElement-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const SVGElementImpl = _SVGElementImplDew().implementation;

  class SVGTitleElementImpl extends SVGElementImpl {}

  exports = {
    implementation: SVGTitleElementImpl
  };
  return exports;
}