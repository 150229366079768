import { dew as _utilsDew } from "../../utils";
import { dew as _SVGElementImplDew } from "./SVGElement-impl";
import { dew as _SVGTestsImplDew } from "./SVGTests-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    mixin
  } = _utilsDew();

  const SVGElementImpl = _SVGElementImplDew().implementation;

  const SVGTestsImpl = _SVGTestsImplDew().implementation;

  class SVGGraphicsElementImpl extends SVGElementImpl {}

  SVGGraphicsElementImpl.attributeRegistry = new Map([...SVGElementImpl.attributeRegistry, ...SVGTestsImpl.attributeRegistry]);
  mixin(SVGGraphicsElementImpl.prototype, SVGTestsImpl.prototype);
  exports.implementation = SVGGraphicsElementImpl;
  return exports;
}