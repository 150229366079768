import { dew as _UIEventImplDew } from "./UIEvent-impl";
import { dew as _InputEventInitDew } from "../generated/InputEventInit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const UIEventImpl = _UIEventImplDew().implementation;

  const InputEventInit = _InputEventInitDew(); // https://w3c.github.io/uievents/#interface-inputevent


  class InputEventImpl extends UIEventImpl {}

  InputEventImpl.defaultInit = InputEventInit.convert(undefined, undefined);
  exports = {
    implementation: InputEventImpl
  };
  return exports;
}