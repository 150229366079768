import { dew as _HTMLCollectionDew } from "../generated/HTMLCollection";
import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _traversalDew } from "../helpers/traversal";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLCollection = _HTMLCollectionDew();

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const {
    descendantsByLocalName
  } = _traversalDew();

  class HTMLDataListElementImpl extends HTMLElementImpl {
    // https://html.spec.whatwg.org/multipage/form-elements.html#dom-datalist-options
    get options() {
      return HTMLCollection.createImpl(this._globalObject, [], {
        element: this,
        query: () => descendantsByLocalName(this, "option")
      });
    }

  }

  exports = {
    implementation: HTMLDataListElementImpl
  };
  return exports;
}