import { dew as _CharacterDataImplDew } from "./CharacterData-impl";
import { dew as _utilsDew } from "../generated/utils";
import { dew as _nodeTypeDew } from "../node-type";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const CharacterDataImpl = _CharacterDataImplDew().implementation;

  const idlUtils = _utilsDew();

  const NODE_TYPE = _nodeTypeDew();

  class CommentImpl extends CharacterDataImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, {
        data: args[0],
        ownerDocument: idlUtils.implForWrapper(globalObject._document),
        ...privateData
      });
      this.nodeType = NODE_TYPE.COMMENT_NODE;
    }

  }

  exports = {
    implementation: CommentImpl
  };
  return exports;
}