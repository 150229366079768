import * as _w3cXmlserializer2 from "w3c-xmlserializer";

var _w3cXmlserializer = "default" in _w3cXmlserializer2 ? _w3cXmlserializer2.default : _w3cXmlserializer2;

import * as _parse2 from "parse5";

var _parse = "default" in _parse2 ? _parse2.default : _parse2;

import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

import { dew as _utilsDew } from "../generated/utils";
import { dew as _parse5AdapterSerializationDew } from "./parse5-adapter-serialization";
import { dew as _nodeTypeDew } from "../node-type";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const produceXMLSerialization = _w3cXmlserializer;
  const parse5 = _parse;
  const DOMException = _webidl2jsWrapper;

  const utils = _utilsDew();

  const treeAdapter = _parse5AdapterSerializationDew();

  const NODE_TYPE = _nodeTypeDew();

  exports.fragmentSerialization = (node, {
    outer,
    requireWellFormed,
    globalObject
  }) => {
    const contextDocument = node.nodeType === NODE_TYPE.DOCUMENT_NODE ? node : node._ownerDocument;

    if (contextDocument._parsingMode === "html") {
      const config = { ...contextDocument._parseOptions,
        treeAdapter
      };
      return outer ? parse5.serializeOuter(node, config) : parse5.serialize(node, config);
    }

    const childNodes = outer ? [node] : node.childNodes;

    try {
      let serialized = "";

      for (let i = 0; i < childNodes.length; ++i) {
        serialized += produceXMLSerialization(utils.wrapperForImpl(childNodes[i] || childNodes.item(i)), {
          requireWellFormed
        });
      }

      return serialized;
    } catch (e) {
      throw DOMException.create(globalObject, [e.message, "InvalidStateError"]);
    }
  };

  return exports;
}