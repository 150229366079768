import { dew as _EventTargetImplDew } from "../events/EventTarget-impl";
import { dew as _utilsDew } from "../generated/utils";
import { dew as _createEventAccessorDew } from "../helpers/create-event-accessor";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const EventTargetImpl = _EventTargetImplDew().implementation;

  const idlUtils = _utilsDew();

  const {
    setupForSimpleEventAccessors
  } = _createEventAccessorDew();

  const events = ["loadstart", "progress", "abort", "error", "load", "timeout", "loadend"];

  class XMLHttpRequestEventTargetImpl extends EventTargetImpl {
    // TODO: remove this when we fix EventTargetImpl to use this._globalObject directly instead of using _ownerDocument.
    // https://github.com/jsdom/jsdom/issues/2780
    get _ownerDocument() {
      return idlUtils.implForWrapper(this._globalObject._document);
    }

  }

  setupForSimpleEventAccessors(XMLHttpRequestEventTargetImpl.prototype, events);
  exports.implementation = XMLHttpRequestEventTargetImpl;
  return exports;
}