import { dew as _EventImplDew } from "./Event-impl.js";
import { dew as _PopStateEventInitDew } from "../generated/PopStateEventInit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const EventImpl = _EventImplDew().implementation;

  const PopStateEventInit = _PopStateEventInitDew();

  class PopStateEventImpl extends EventImpl {}

  PopStateEventImpl.defaultInit = PopStateEventInit.convert(undefined, undefined);
  exports.implementation = PopStateEventImpl;
  return exports;
}