import { dew as _UIEventImplDew } from "./UIEvent-impl";
import { dew as _CompositionEventInitDew } from "../generated/CompositionEventInit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const UIEventImpl = _UIEventImplDew().implementation;

  const CompositionEventInit = _CompositionEventInitDew();

  class CompositionEventImpl extends UIEventImpl {
    initCompositionEvent(type, bubbles, cancelable, view, data) {
      if (this._dispatchFlag) {
        return;
      }

      this.initUIEvent(type, bubbles, cancelable, view, 0);
      this.data = data;
    }

  }

  CompositionEventImpl.defaultInit = CompositionEventInit.convert(undefined, undefined);
  exports = {
    implementation: CompositionEventImpl
  };
  return exports;
}