import { dew as _parserDew } from "../../browser/parser";
import { dew as _namespacesDew } from "../helpers/namespaces.js";
import { dew as _shadowDomDew } from "../helpers/shadow-dom.js";
import { dew as _nodeTypeDew } from "../node-type.js";
import { dew as _serializationDew } from "./serialization.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    parseFragment
  } = _parserDew();

  const {
    HTML_NS
  } = _namespacesDew();

  const {
    isShadowRoot
  } = _shadowDomDew();

  const NODE_TYPE = _nodeTypeDew();

  const {
    fragmentSerialization
  } = _serializationDew(); // https://w3c.github.io/DOM-Parsing/#the-innerhtml-mixin


  exports.implementation = class InnerHTMLImpl {
    // https://w3c.github.io/DOM-Parsing/#dom-innerhtml-innerhtml
    get innerHTML() {
      return fragmentSerialization(this, {
        outer: false,
        requireWellFormed: true,
        globalObject: this._globalObject
      });
    }

    set innerHTML(markup) {
      const contextElement = isShadowRoot(this) ? this.host : this;
      const fragment = parseFragment(markup, contextElement);
      let contextObject = this;

      if (this.nodeType === NODE_TYPE.ELEMENT_NODE && this.localName === "template" && this.namespaceURI === HTML_NS) {
        contextObject = this._templateContents;
      }

      contextObject._replaceAll(fragment);
    }

  };
  return exports;
}