import { dew as _UIEventImplDew } from "./UIEvent-impl";
import { dew as _TouchEventInitDew } from "../generated/TouchEventInit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const UIEventImpl = _UIEventImplDew().implementation;

  const TouchEventInit = _TouchEventInitDew();

  class TouchEventImpl extends UIEventImpl {}

  TouchEventImpl.defaultInit = TouchEventInit.convert(undefined, undefined);
  exports = {
    implementation: TouchEventImpl
  };
  return exports;
}