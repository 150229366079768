import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const DOMException = _webidl2jsWrapper;
  const conversions = _webidlConversions;
  exports.FILTER_ACCEPT = 1; // NodeFilter.FILTER_ACCEPT

  exports.FILTER_REJECT = 2; // NodeFilter.FILTER_REJECT

  exports.FILTER_SKIP = 3; // NodeFilter.FILTER_SKIP

  exports.filter = (nodeIteratorOrTreeWalkerImpl, nodeImpl) => {
    if (nodeIteratorOrTreeWalkerImpl._active) {
      throw DOMException.create(nodeIteratorOrTreeWalkerImpl._globalObject, ["Recursive node filtering", "InvalidStateError"]);
    }

    const n = nodeImpl.nodeType - 1;

    if (!(1 << n & nodeIteratorOrTreeWalkerImpl.whatToShow)) {
      return exports.FILTER_SKIP;
    } // Saving in a variable is important so we don't accidentally call it as a method later.


    const {
      filter
    } = nodeIteratorOrTreeWalkerImpl;

    if (filter === null) {
      return exports.FILTER_ACCEPT;
    }

    nodeIteratorOrTreeWalkerImpl._active = true;
    let result; // https://github.com/whatwg/dom/issues/494

    try {
      result = filter(nodeImpl);
    } finally {
      nodeIteratorOrTreeWalkerImpl._active = false;
    }

    result = conversions["unsigned short"](result);
    return result;
  };

  return exports;
}