import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

import { dew as _StorageEventDew } from "../generated/StorageEvent";
import { dew as _utilsDew } from "../generated/utils";
import { dew as _eventsDew } from "../helpers/events";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const DOMException = _webidl2jsWrapper;

  const StorageEvent = _StorageEventDew();

  const idlUtils = _utilsDew();

  const {
    fireAnEvent
  } = _eventsDew(); // https://html.spec.whatwg.org/multipage/webstorage.html#the-storage-interface


  class StorageImpl {
    constructor(globalObject, args, privateData) {
      const {
        associatedWindow,
        storageArea,
        url,
        type,
        storageQuota
      } = privateData;
      this._associatedWindow = associatedWindow;
      this._items = storageArea;
      this._url = url;
      this._type = type;
      this._quota = storageQuota;
      this._globalObject = globalObject;
    }

    _dispatchStorageEvent(key, oldValue, newValue) {
      return this._associatedWindow._currentOriginData.windowsInSameOrigin.filter(target => target !== this._associatedWindow).forEach(target => fireAnEvent("storage", target, StorageEvent, {
        key,
        oldValue,
        newValue,
        url: this._url,
        storageArea: target["_" + this._type]
      }));
    }

    get length() {
      return this._items.size;
    }

    key(n) {
      if (n >= this._items.size) {
        return null;
      }

      return [...this._items.keys()][n];
    }

    getItem(key) {
      if (this._items.has(key)) {
        return this._items.get(key);
      }

      return null;
    }

    setItem(key, value) {
      const oldValue = this._items.get(key) || null;

      if (oldValue === value) {
        return;
      } // Concatenate all keys and values to measure their length against the quota


      let itemsTotalLength = key.length + value.length;

      for (const [curKey, curValue] of this._items) {
        // If the key already exists, skip it as it will be set to the new value instead
        if (key !== curKey) {
          itemsTotalLength += curKey.length + curValue.length;
        }
      }

      if (itemsTotalLength > this._quota) {
        throw DOMException.create(this._globalObject, [`The ${this._quota}-code unit storage quota has been exceeded.`, "QuotaExceededError"]);
      }

      setTimeout(this._dispatchStorageEvent.bind(this), 0, key, oldValue, value);

      this._items.set(key, value);
    }

    removeItem(key) {
      if (this._items.has(key)) {
        setTimeout(this._dispatchStorageEvent.bind(this), 0, key, this._items.get(key), null);

        this._items.delete(key);
      }
    }

    clear() {
      if (this._items.size > 0) {
        setTimeout(this._dispatchStorageEvent.bind(this), 0, null, null, null);

        this._items.clear();
      }
    }

    get [idlUtils.supportedPropertyNames]() {
      return this._items.keys();
    }

  }

  exports = {
    implementation: StorageImpl
  };
  return exports;
}