import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _HTMLTableCaptionElementDew } from "./HTMLTableCaptionElement.js";
import { dew as _HTMLTableSectionElementDew } from "./HTMLTableSectionElement.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _HTMLTableElementImplDew } from "../nodes/HTMLTableElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const HTMLTableCaptionElement = _HTMLTableCaptionElementDew();

  const HTMLTableSectionElement = _HTMLTableSectionElementDew();

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const HTMLElement = _HTMLElementDew();

  const interfaceName = "HTMLTableElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLTableElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLTableElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLTableElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      createCaption() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'createCaption' called on an object that is not a valid instance of HTMLTableElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol].createCaption());
      }

      deleteCaption() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'deleteCaption' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].deleteCaption();
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      createTHead() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'createTHead' called on an object that is not a valid instance of HTMLTableElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol].createTHead());
      }

      deleteTHead() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'deleteTHead' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].deleteTHead();
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      createTFoot() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'createTFoot' called on an object that is not a valid instance of HTMLTableElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol].createTFoot());
      }

      deleteTFoot() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'deleteTFoot' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].deleteTFoot();
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      createTBody() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'createTBody' called on an object that is not a valid instance of HTMLTableElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol].createTBody());
      }

      insertRow() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'insertRow' called on an object that is not a valid instance of HTMLTableElement.");
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg !== undefined) {
            curArg = conversions["long"](curArg, {
              context: "Failed to execute 'insertRow' on 'HTMLTableElement': parameter 1",
              globals: globalObject
            });
          } else {
            curArg = -1;
          }

          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].insertRow(...args));
      }

      deleteRow(index) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'deleteRow' called on an object that is not a valid instance of HTMLTableElement.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'deleteRow' on 'HTMLTableElement': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["long"](curArg, {
            context: "Failed to execute 'deleteRow' on 'HTMLTableElement': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].deleteRow(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get caption() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get caption' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return utils.tryWrapperForImpl(esValue[implSymbol]["caption"]);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set caption(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set caption' called on an object that is not a valid instance of HTMLTableElement.");
        }

        if (V === null || V === undefined) {
          V = null;
        } else {
          V = HTMLTableCaptionElement.convert(globalObject, V, {
            context: "Failed to set the 'caption' property on 'HTMLTableElement': The provided value"
          });
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["caption"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get tHead() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get tHead' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return utils.tryWrapperForImpl(esValue[implSymbol]["tHead"]);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set tHead(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set tHead' called on an object that is not a valid instance of HTMLTableElement.");
        }

        if (V === null || V === undefined) {
          V = null;
        } else {
          V = HTMLTableSectionElement.convert(globalObject, V, {
            context: "Failed to set the 'tHead' property on 'HTMLTableElement': The provided value"
          });
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["tHead"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get tFoot() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get tFoot' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return utils.tryWrapperForImpl(esValue[implSymbol]["tFoot"]);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set tFoot(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set tFoot' called on an object that is not a valid instance of HTMLTableElement.");
        }

        if (V === null || V === undefined) {
          V = null;
        } else {
          V = HTMLTableSectionElement.convert(globalObject, V, {
            context: "Failed to set the 'tFoot' property on 'HTMLTableElement': The provided value"
          });
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["tFoot"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get tBodies() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get tBodies' called on an object that is not a valid instance of HTMLTableElement.");
        }

        return utils.getSameObject(this, "tBodies", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["tBodies"]);
        });
      }

      get rows() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get rows' called on an object that is not a valid instance of HTMLTableElement.");
        }

        return utils.getSameObject(this, "rows", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["rows"]);
        });
      }

      get align() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get align' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "align");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set align(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set align' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'align' property on 'HTMLTableElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "align", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get border() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get border' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "border");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set border(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set border' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'border' property on 'HTMLTableElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "border", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get frame() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get frame' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "frame");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set frame(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set frame' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'frame' property on 'HTMLTableElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "frame", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get rules() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get rules' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "rules");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set rules(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set rules' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'rules' property on 'HTMLTableElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "rules", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get summary() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get summary' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "summary");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set summary(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set summary' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'summary' property on 'HTMLTableElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "summary", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get width() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get width' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "width");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set width(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set width' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'width' property on 'HTMLTableElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "width", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get bgColor() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get bgColor' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "bgcolor");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set bgColor(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set bgColor' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'bgColor' property on 'HTMLTableElement': The provided value",
          globals: globalObject,
          treatNullAsEmptyString: true
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "bgcolor", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get cellPadding() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get cellPadding' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "cellpadding");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set cellPadding(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set cellPadding' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'cellPadding' property on 'HTMLTableElement': The provided value",
          globals: globalObject,
          treatNullAsEmptyString: true
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "cellpadding", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get cellSpacing() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get cellSpacing' called on an object that is not a valid instance of HTMLTableElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "cellspacing");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set cellSpacing(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set cellSpacing' called on an object that is not a valid instance of HTMLTableElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'cellSpacing' property on 'HTMLTableElement': The provided value",
          globals: globalObject,
          treatNullAsEmptyString: true
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "cellspacing", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

    }

    Object.defineProperties(HTMLTableElement.prototype, {
      createCaption: {
        enumerable: true
      },
      deleteCaption: {
        enumerable: true
      },
      createTHead: {
        enumerable: true
      },
      deleteTHead: {
        enumerable: true
      },
      createTFoot: {
        enumerable: true
      },
      deleteTFoot: {
        enumerable: true
      },
      createTBody: {
        enumerable: true
      },
      insertRow: {
        enumerable: true
      },
      deleteRow: {
        enumerable: true
      },
      caption: {
        enumerable: true
      },
      tHead: {
        enumerable: true
      },
      tFoot: {
        enumerable: true
      },
      tBodies: {
        enumerable: true
      },
      rows: {
        enumerable: true
      },
      align: {
        enumerable: true
      },
      border: {
        enumerable: true
      },
      frame: {
        enumerable: true
      },
      rules: {
        enumerable: true
      },
      summary: {
        enumerable: true
      },
      width: {
        enumerable: true
      },
      bgColor: {
        enumerable: true
      },
      cellPadding: {
        enumerable: true
      },
      cellSpacing: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLTableElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLTableElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLTableElement
    });
  };

  const Impl = _HTMLTableElementImplDew();

  return exports;
}