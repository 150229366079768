var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  // https://drafts.csswg.org/cssom-view-1/#the-screen-interface
  class ScreenImpl {}

  ScreenImpl.prototype.availWidth = 0;
  ScreenImpl.prototype.availHeight = 0;
  ScreenImpl.prototype.width = 0;
  ScreenImpl.prototype.height = 0;
  ScreenImpl.prototype.colorDepth = 24;
  ScreenImpl.prototype.pixelDepth = 24;
  exports.implementation = ScreenImpl;
  return exports;
}