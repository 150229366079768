import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _DOMStringMapImplDew } from "../nodes/DOMStringMap-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;
  const interfaceName = "DOMStringMap";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'DOMStringMap'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["DOMStringMap"].prototype;
    }

    return Object.create(proto);
  }

  function makeProxy(wrapper, globalObject) {
    let proxyHandler = proxyHandlerCache.get(globalObject);

    if (proxyHandler === undefined) {
      proxyHandler = new ProxyHandler(globalObject);
      proxyHandlerCache.set(globalObject, proxyHandler);
    }

    return new Proxy(wrapper, proxyHandler);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {};

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper = makeProxy(wrapper, globalObject);
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    let wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper = makeProxy(wrapper, globalObject);
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class DOMStringMap {
      constructor() {
        throw new globalObject.TypeError("Illegal constructor");
      }

    }

    Object.defineProperties(DOMStringMap.prototype, {
      [Symbol.toStringTag]: {
        value: "DOMStringMap",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = DOMStringMap;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: DOMStringMap
    });
  };

  const proxyHandlerCache = new WeakMap();

  class ProxyHandler {
    constructor(globalObject) {
      this._globalObject = globalObject;
    }

    get(target, P, receiver) {
      if (typeof P === "symbol") {
        return Reflect.get(target, P, receiver);
      }

      const desc = this.getOwnPropertyDescriptor(target, P);

      if (desc === undefined) {
        const parent = Object.getPrototypeOf(target);

        if (parent === null) {
          return undefined;
        }

        return Reflect.get(target, P, receiver);
      }

      if (!desc.get && !desc.set) {
        return desc.value;
      }

      const getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return Reflect.apply(getter, receiver, []);
    }

    has(target, P) {
      if (typeof P === "symbol") {
        return Reflect.has(target, P);
      }

      const desc = this.getOwnPropertyDescriptor(target, P);

      if (desc !== undefined) {
        return true;
      }

      const parent = Object.getPrototypeOf(target);

      if (parent !== null) {
        return Reflect.has(parent, P);
      }

      return false;
    }

    ownKeys(target) {
      const keys = new Set();

      for (const key of target[implSymbol][utils.supportedPropertyNames]) {
        if (!utils.hasOwn(target, key)) {
          keys.add(`${key}`);
        }
      }

      for (const key of Reflect.ownKeys(target)) {
        keys.add(key);
      }

      return [...keys];
    }

    getOwnPropertyDescriptor(target, P) {
      if (typeof P === "symbol") {
        return Reflect.getOwnPropertyDescriptor(target, P);
      }

      let ignoreNamedProps = false;
      const namedValue = target[implSymbol][utils.namedGet](P);

      if (namedValue !== undefined && !utils.hasOwn(target, P) && !ignoreNamedProps) {
        return {
          writable: true,
          enumerable: true,
          configurable: true,
          value: utils.tryWrapperForImpl(namedValue)
        };
      }

      return Reflect.getOwnPropertyDescriptor(target, P);
    }

    set(target, P, V, receiver) {
      if (typeof P === "symbol") {
        return Reflect.set(target, P, V, receiver);
      } // The `receiver` argument refers to the Proxy exotic object or an object
      // that inherits from it, whereas `target` refers to the Proxy target:


      if (target[implSymbol][utils.wrapperSymbol] === receiver) {
        const globalObject = this._globalObject;

        if (typeof P === "string") {
          let namedValue = V;
          namedValue = conversions["DOMString"](namedValue, {
            context: "Failed to set the '" + P + "' property on 'DOMStringMap': The provided value",
            globals: globalObject
          });
          ceReactionsPreSteps_helpers_custom_elements(globalObject);

          try {
            const creating = !(target[implSymbol][utils.namedGet](P) !== undefined);

            if (creating) {
              target[implSymbol][utils.namedSetNew](P, namedValue);
            } else {
              target[implSymbol][utils.namedSetExisting](P, namedValue);
            }
          } finally {
            ceReactionsPostSteps_helpers_custom_elements(globalObject);
          }

          return true;
        }
      }

      let ownDesc;

      if (ownDesc === undefined) {
        ownDesc = Reflect.getOwnPropertyDescriptor(target, P);
      }

      if (ownDesc === undefined) {
        const parent = Reflect.getPrototypeOf(target);

        if (parent !== null) {
          return Reflect.set(parent, P, V, receiver);
        }

        ownDesc = {
          writable: true,
          enumerable: true,
          configurable: true,
          value: undefined
        };
      }

      if (!ownDesc.writable) {
        return false;
      }

      if (!utils.isObject(receiver)) {
        return false;
      }

      const existingDesc = Reflect.getOwnPropertyDescriptor(receiver, P);
      let valueDesc;

      if (existingDesc !== undefined) {
        if (existingDesc.get || existingDesc.set) {
          return false;
        }

        if (!existingDesc.writable) {
          return false;
        }

        valueDesc = {
          value: V
        };
      } else {
        valueDesc = {
          writable: true,
          enumerable: true,
          configurable: true,
          value: V
        };
      }

      return Reflect.defineProperty(receiver, P, valueDesc);
    }

    defineProperty(target, P, desc) {
      if (typeof P === "symbol") {
        return Reflect.defineProperty(target, P, desc);
      }

      const globalObject = this._globalObject;

      if (desc.get || desc.set) {
        return false;
      }

      let namedValue = desc.value;
      namedValue = conversions["DOMString"](namedValue, {
        context: "Failed to set the '" + P + "' property on 'DOMStringMap': The provided value",
        globals: globalObject
      });
      ceReactionsPreSteps_helpers_custom_elements(globalObject);

      try {
        const creating = !(target[implSymbol][utils.namedGet](P) !== undefined);

        if (creating) {
          target[implSymbol][utils.namedSetNew](P, namedValue);
        } else {
          target[implSymbol][utils.namedSetExisting](P, namedValue);
        }
      } finally {
        ceReactionsPostSteps_helpers_custom_elements(globalObject);
      }

      return true;
    }

    deleteProperty(target, P) {
      if (typeof P === "symbol") {
        return Reflect.deleteProperty(target, P);
      }

      const globalObject = this._globalObject;

      if (target[implSymbol][utils.namedGet](P) !== undefined && !utils.hasOwn(target, P)) {
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          target[implSymbol][utils.namedDelete](P);
          return true;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      return Reflect.deleteProperty(target, P);
    }

    preventExtensions() {
      return false;
    }

  }

  const Impl = _DOMStringMapImplDew();

  return exports;
}