import { dew as _utilsDew } from "../../utils";
import { dew as _SVGListBaseDew } from "./SVGListBase";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    mixin
  } = _utilsDew();

  const SVGListBase = _SVGListBaseDew();

  class SVGStringListImpl {
    constructor(globalObject, args, privateData) {
      this._globalObject = globalObject;

      this._initList(privateData);
    }

  }

  mixin(SVGStringListImpl.prototype, SVGListBase.prototype);
  exports.implementation = SVGStringListImpl;
  return exports;
}