import { dew as _HTMLMediaElementImplDew } from "./HTMLMediaElement-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLMediaElementImpl = _HTMLMediaElementImplDew().implementation;

  class HTMLAudioElementImpl extends HTMLMediaElementImpl {}

  exports = {
    implementation: HTMLAudioElementImpl
  };
  return exports;
}