var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  exports.implementation = class NavigatorPluginsImpl {
    // plugins and mimeTypes are implemented in Navigator-impl.js
    javaEnabled() {
      return false;
    }

  };
  return exports;
}