import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _documentBaseUrlDew } from "../helpers/document-base-url.js";
import * as _whatwgUrl2 from "whatwg-url";

var _whatwgUrl = "default" in _whatwgUrl2 ? _whatwgUrl2.default : _whatwgUrl2;

import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _stringsDew } from "../helpers/strings.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _HTMLObjectElementImplDew } from "../nodes/HTMLObjectElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const parseURLToResultingURLRecord_helpers_document_base_url = _documentBaseUrlDew().parseURLToResultingURLRecord;

  const serializeURLwhatwg_url = _whatwgUrl.serializeURL;

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const parseNonNegativeInteger_helpers_strings = _stringsDew().parseNonNegativeInteger;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const HTMLElement = _HTMLElementDew();

  const interfaceName = "HTMLObjectElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLObjectElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLObjectElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLObjectElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      checkValidity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'checkValidity' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        return esValue[implSymbol].checkValidity();
      }

      reportValidity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'reportValidity' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        return esValue[implSymbol].reportValidity();
      }

      setCustomValidity(error) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'setCustomValidity' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'setCustomValidity' on 'HTMLObjectElement': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'setCustomValidity' on 'HTMLObjectElement': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].setCustomValidity(...args);
      }

      get data() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get data' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "data");

          if (value === null) {
            return "";
          }

          const urlRecord = parseURLToResultingURLRecord_helpers_document_base_url(value, esValue[implSymbol]._ownerDocument);

          if (urlRecord !== null) {
            return serializeURLwhatwg_url(urlRecord);
          }

          return conversions.USVString(value);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set data(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set data' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'data' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "data", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get type() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get type' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "type");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set type(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set type' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'type' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "type", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get name() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get name' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "name");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set name(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set name' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'name' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "name", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get useMap() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get useMap' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "usemap");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set useMap(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set useMap' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'useMap' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "usemap", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get form() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get form' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["form"]);
      }

      get width() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get width' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "width");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set width(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set width' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'width' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "width", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get height() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get height' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "height");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set height(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set height' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'height' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "height", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get contentDocument() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get contentDocument' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["contentDocument"]);
      }

      get willValidate() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get willValidate' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        return esValue[implSymbol]["willValidate"];
      }

      get validity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get validity' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["validity"]);
      }

      get validationMessage() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get validationMessage' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        return esValue[implSymbol]["validationMessage"];
      }

      get align() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get align' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "align");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set align(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set align' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'align' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "align", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get archive() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get archive' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "archive");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set archive(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set archive' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'archive' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "archive", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get code() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get code' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "code");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set code(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set code' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'code' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "code", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get declare() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get declare' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "declare");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set declare(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set declare' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'declare' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "declare", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "declare");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get hspace() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get hspace' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "hspace");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set hspace(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set hspace' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'hspace' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "hspace", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get standby() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get standby' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "standby");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set standby(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set standby' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'standby' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "standby", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get vspace() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get vspace' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "vspace");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set vspace(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set vspace' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'vspace' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "vspace", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get codeBase() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get codeBase' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "codebase");

          if (value === null) {
            return "";
          }

          const urlRecord = parseURLToResultingURLRecord_helpers_document_base_url(value, esValue[implSymbol]._ownerDocument);

          if (urlRecord !== null) {
            return serializeURLwhatwg_url(urlRecord);
          }

          return conversions.USVString(value);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set codeBase(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set codeBase' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'codeBase' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "codebase", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get codeType() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get codeType' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "codetype");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set codeType(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set codeType' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'codeType' property on 'HTMLObjectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "codetype", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get border() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get border' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "border");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set border(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set border' called on an object that is not a valid instance of HTMLObjectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'border' property on 'HTMLObjectElement': The provided value",
          globals: globalObject,
          treatNullAsEmptyString: true
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "border", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

    }

    Object.defineProperties(HTMLObjectElement.prototype, {
      checkValidity: {
        enumerable: true
      },
      reportValidity: {
        enumerable: true
      },
      setCustomValidity: {
        enumerable: true
      },
      data: {
        enumerable: true
      },
      type: {
        enumerable: true
      },
      name: {
        enumerable: true
      },
      useMap: {
        enumerable: true
      },
      form: {
        enumerable: true
      },
      width: {
        enumerable: true
      },
      height: {
        enumerable: true
      },
      contentDocument: {
        enumerable: true
      },
      willValidate: {
        enumerable: true
      },
      validity: {
        enumerable: true
      },
      validationMessage: {
        enumerable: true
      },
      align: {
        enumerable: true
      },
      archive: {
        enumerable: true
      },
      code: {
        enumerable: true
      },
      declare: {
        enumerable: true
      },
      hspace: {
        enumerable: true
      },
      standby: {
        enumerable: true
      },
      vspace: {
        enumerable: true
      },
      codeBase: {
        enumerable: true
      },
      codeType: {
        enumerable: true
      },
      border: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLObjectElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLObjectElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLObjectElement
    });
  };

  const Impl = _HTMLObjectElementImplDew();

  return exports;
}