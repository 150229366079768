import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _textDew } from "../helpers/text";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const {
    childTextContent
  } = _textDew();

  class HTMLTitleElementImpl extends HTMLElementImpl {
    get text() {
      return childTextContent(this);
    }

    set text(value) {
      this.textContent = value;
    }

  }

  exports = {
    implementation: HTMLTitleElementImpl
  };
  return exports;
}