import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  class HTMLHtmlElementImpl extends HTMLElementImpl {}

  exports = {
    implementation: HTMLHtmlElementImpl
  };
  return exports;
}