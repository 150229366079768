import { dew as _TextImplDew } from "./Text-impl";
import { dew as _nodeTypeDew } from "../node-type";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const TextImpl = _TextImplDew().implementation;

  const NODE_TYPE = _nodeTypeDew();

  class CDATASectionImpl extends TextImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, privateData);
      this.nodeType = NODE_TYPE.CDATA_SECTION_NODE;
    }

  }

  exports = {
    implementation: CDATASectionImpl
  };
  return exports;
}