import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _DOMTokenListImplDew } from "../nodes/DOMTokenList-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;
  const interfaceName = "DOMTokenList";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'DOMTokenList'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["DOMTokenList"].prototype;
    }

    return Object.create(proto);
  }

  function makeProxy(wrapper, globalObject) {
    let proxyHandler = proxyHandlerCache.get(globalObject);

    if (proxyHandler === undefined) {
      proxyHandler = new ProxyHandler(globalObject);
      proxyHandlerCache.set(globalObject, proxyHandler);
    }

    return new Proxy(wrapper, proxyHandler);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {};

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper = makeProxy(wrapper, globalObject);
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    let wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper = makeProxy(wrapper, globalObject);
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class DOMTokenList {
      constructor() {
        throw new globalObject.TypeError("Illegal constructor");
      }

      item(index) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'item' called on an object that is not a valid instance of DOMTokenList.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'item' on 'DOMTokenList': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["unsigned long"](curArg, {
            context: "Failed to execute 'item' on 'DOMTokenList': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].item(...args);
      }

      contains(token) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'contains' called on an object that is not a valid instance of DOMTokenList.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'contains' on 'DOMTokenList': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'contains' on 'DOMTokenList': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].contains(...args);
      }

      add() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'add' called on an object that is not a valid instance of DOMTokenList.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'add' on 'DOMTokenList': parameter " + (i + 1),
            globals: globalObject
          });
          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].add(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      remove() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'remove' called on an object that is not a valid instance of DOMTokenList.");
        }

        const args = [];

        for (let i = 0; i < arguments.length; i++) {
          let curArg = arguments[i];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'remove' on 'DOMTokenList': parameter " + (i + 1),
            globals: globalObject
          });
          args.push(curArg);
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].remove(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      toggle(token) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'toggle' called on an object that is not a valid instance of DOMTokenList.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'toggle' on 'DOMTokenList': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'toggle' on 'DOMTokenList': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];

          if (curArg !== undefined) {
            curArg = conversions["boolean"](curArg, {
              context: "Failed to execute 'toggle' on 'DOMTokenList': parameter 2",
              globals: globalObject
            });
          }

          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].toggle(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      replace(token, newToken) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'replace' called on an object that is not a valid instance of DOMTokenList.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'replace' on 'DOMTokenList': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'replace' on 'DOMTokenList': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'replace' on 'DOMTokenList': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].replace(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      supports(token) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'supports' called on an object that is not a valid instance of DOMTokenList.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'supports' on 'DOMTokenList': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'supports' on 'DOMTokenList': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].supports(...args);
      }

      get length() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get length' called on an object that is not a valid instance of DOMTokenList.");
        }

        return esValue[implSymbol]["length"];
      }

      get value() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get value' called on an object that is not a valid instance of DOMTokenList.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["value"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set value(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set value' called on an object that is not a valid instance of DOMTokenList.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'value' property on 'DOMTokenList': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["value"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      toString() {
        const esValue = this;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'toString' called on an object that is not a valid instance of DOMTokenList.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["value"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

    }

    Object.defineProperties(DOMTokenList.prototype, {
      item: {
        enumerable: true
      },
      contains: {
        enumerable: true
      },
      add: {
        enumerable: true
      },
      remove: {
        enumerable: true
      },
      toggle: {
        enumerable: true
      },
      replace: {
        enumerable: true
      },
      supports: {
        enumerable: true
      },
      length: {
        enumerable: true
      },
      value: {
        enumerable: true
      },
      toString: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "DOMTokenList",
        configurable: true
      },
      [Symbol.iterator]: {
        value: globalObject.Array.prototype[Symbol.iterator],
        configurable: true,
        writable: true
      },
      keys: {
        value: globalObject.Array.prototype.keys,
        configurable: true,
        enumerable: true,
        writable: true
      },
      values: {
        value: globalObject.Array.prototype.values,
        configurable: true,
        enumerable: true,
        writable: true
      },
      entries: {
        value: globalObject.Array.prototype.entries,
        configurable: true,
        enumerable: true,
        writable: true
      },
      forEach: {
        value: globalObject.Array.prototype.forEach,
        configurable: true,
        enumerable: true,
        writable: true
      }
    });
    ctorRegistry[interfaceName] = DOMTokenList;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: DOMTokenList
    });
  };

  const proxyHandlerCache = new WeakMap();

  class ProxyHandler {
    constructor(globalObject) {
      this._globalObject = globalObject;
    }

    get(target, P, receiver) {
      if (typeof P === "symbol") {
        return Reflect.get(target, P, receiver);
      }

      const desc = this.getOwnPropertyDescriptor(target, P);

      if (desc === undefined) {
        const parent = Object.getPrototypeOf(target);

        if (parent === null) {
          return undefined;
        }

        return Reflect.get(target, P, receiver);
      }

      if (!desc.get && !desc.set) {
        return desc.value;
      }

      const getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return Reflect.apply(getter, receiver, []);
    }

    has(target, P) {
      if (typeof P === "symbol") {
        return Reflect.has(target, P);
      }

      const desc = this.getOwnPropertyDescriptor(target, P);

      if (desc !== undefined) {
        return true;
      }

      const parent = Object.getPrototypeOf(target);

      if (parent !== null) {
        return Reflect.has(parent, P);
      }

      return false;
    }

    ownKeys(target) {
      const keys = new Set();

      for (const key of target[implSymbol][utils.supportedPropertyIndices]) {
        keys.add(`${key}`);
      }

      for (const key of Reflect.ownKeys(target)) {
        keys.add(key);
      }

      return [...keys];
    }

    getOwnPropertyDescriptor(target, P) {
      if (typeof P === "symbol") {
        return Reflect.getOwnPropertyDescriptor(target, P);
      }

      let ignoreNamedProps = false;

      if (utils.isArrayIndexPropName(P)) {
        const index = P >>> 0;
        const indexedValue = target[implSymbol].item(index);

        if (indexedValue !== null) {
          return {
            writable: false,
            enumerable: true,
            configurable: true,
            value: utils.tryWrapperForImpl(indexedValue)
          };
        }

        ignoreNamedProps = true;
      }

      return Reflect.getOwnPropertyDescriptor(target, P);
    }

    set(target, P, V, receiver) {
      if (typeof P === "symbol") {
        return Reflect.set(target, P, V, receiver);
      } // The `receiver` argument refers to the Proxy exotic object or an object
      // that inherits from it, whereas `target` refers to the Proxy target:


      if (target[implSymbol][utils.wrapperSymbol] === receiver) {
        const globalObject = this._globalObject;
      }

      let ownDesc;

      if (utils.isArrayIndexPropName(P)) {
        const index = P >>> 0;
        const indexedValue = target[implSymbol].item(index);

        if (indexedValue !== null) {
          ownDesc = {
            writable: false,
            enumerable: true,
            configurable: true,
            value: utils.tryWrapperForImpl(indexedValue)
          };
        }
      }

      if (ownDesc === undefined) {
        ownDesc = Reflect.getOwnPropertyDescriptor(target, P);
      }

      if (ownDesc === undefined) {
        const parent = Reflect.getPrototypeOf(target);

        if (parent !== null) {
          return Reflect.set(parent, P, V, receiver);
        }

        ownDesc = {
          writable: true,
          enumerable: true,
          configurable: true,
          value: undefined
        };
      }

      if (!ownDesc.writable) {
        return false;
      }

      if (!utils.isObject(receiver)) {
        return false;
      }

      const existingDesc = Reflect.getOwnPropertyDescriptor(receiver, P);
      let valueDesc;

      if (existingDesc !== undefined) {
        if (existingDesc.get || existingDesc.set) {
          return false;
        }

        if (!existingDesc.writable) {
          return false;
        }

        valueDesc = {
          value: V
        };
      } else {
        valueDesc = {
          writable: true,
          enumerable: true,
          configurable: true,
          value: V
        };
      }

      return Reflect.defineProperty(receiver, P, valueDesc);
    }

    defineProperty(target, P, desc) {
      if (typeof P === "symbol") {
        return Reflect.defineProperty(target, P, desc);
      }

      const globalObject = this._globalObject;

      if (utils.isArrayIndexPropName(P)) {
        return false;
      }

      return Reflect.defineProperty(target, P, desc);
    }

    deleteProperty(target, P) {
      if (typeof P === "symbol") {
        return Reflect.deleteProperty(target, P);
      }

      const globalObject = this._globalObject;

      if (utils.isArrayIndexPropName(P)) {
        const index = P >>> 0;
        return !(target[implSymbol].item(index) !== null);
      }

      return Reflect.deleteProperty(target, P);
    }

    preventExtensions() {
      return false;
    }

  }

  const Impl = _DOMTokenListImplDew();

  return exports;
}