import { dew as _HTMLMediaElementImplDew } from "./HTMLMediaElement-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLMediaElementImpl = _HTMLMediaElementImplDew().implementation;

  class HTMLVideoElementImpl extends HTMLMediaElementImpl {
    get videoWidth() {
      return 0;
    }

    get videoHeight() {
      return 0;
    }

  }

  exports = {
    implementation: HTMLVideoElementImpl
  };
  return exports;
}