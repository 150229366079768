import { dew as _EventImplDew } from "./Event-impl";
import { dew as _HashChangeEventInitDew } from "../generated/HashChangeEventInit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const EventImpl = _EventImplDew().implementation;

  const HashChangeEventInit = _HashChangeEventInitDew();

  class HashChangeEventImpl extends EventImpl {}

  HashChangeEventImpl.defaultInit = HashChangeEventInit.convert(undefined, undefined);
  exports = {
    implementation: HashChangeEventImpl
  };
  return exports;
}