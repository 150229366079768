import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _MouseEventInitDew } from "./MouseEventInit.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const MouseEventInit = _MouseEventInitDew();

  exports._convertInherit = (globalObject, obj, ret, {
    context = "The provided value"
  } = {}) => {
    MouseEventInit._convertInherit(globalObject, obj, ret, {
      context
    });

    {
      const key = "deltaMode";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["unsigned long"](value, {
          context: context + " has member 'deltaMode' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = 0;
      }
    }
    {
      const key = "deltaX";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["double"](value, {
          context: context + " has member 'deltaX' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = 0;
      }
    }
    {
      const key = "deltaY";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["double"](value, {
          context: context + " has member 'deltaY' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = 0;
      }
    }
    {
      const key = "deltaZ";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        value = conversions["double"](value, {
          context: context + " has member 'deltaZ' that",
          globals: globalObject
        });
        ret[key] = value;
      } else {
        ret[key] = 0;
      }
    }
  };

  exports.convert = (globalObject, obj, {
    context = "The provided value"
  } = {}) => {
    if (obj !== undefined && typeof obj !== "object" && typeof obj !== "function") {
      throw new globalObject.TypeError(`${context} is not an object.`);
    }

    const ret = Object.create(null);

    exports._convertInherit(globalObject, obj, ret, {
      context
    });

    return ret;
  };

  return exports;
}