import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _DefaultConstraintValidationImplDew } from "../constraint-validation/DefaultConstraintValidation-impl";
import { dew as _utilsDew } from "../../utils";
import { dew as _formControlsDew } from "../helpers/form-controls";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const DefaultConstraintValidationImpl = _DefaultConstraintValidationImplDew().implementation;

  const {
    mixin
  } = _utilsDew();

  const {
    formOwner
  } = _formControlsDew();

  class HTMLObjectElementImpl extends HTMLElementImpl {
    get form() {
      return formOwner(this);
    }

    get contentDocument() {
      return null;
    }

    _barredFromConstraintValidationSpecialization() {
      return true;
    }

  }

  mixin(HTMLObjectElementImpl.prototype, DefaultConstraintValidationImpl.prototype);
  exports = {
    implementation: HTMLObjectElementImpl
  };
  return exports;
}