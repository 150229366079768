import { dew as _HTMLFrameElementImplDew } from "./HTMLFrameElement-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLFrameElementImpl = _HTMLFrameElementImplDew().implementation;

  class HTMLIFrameElementImpl extends HTMLFrameElementImpl {}

  exports = {
    implementation: HTMLIFrameElementImpl
  };
  return exports;
}