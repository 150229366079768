var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  // https://dom.spec.whatwg.org/#interface-nonelementparentnode
  // getElementById is implemented separately inside Document and DocumentFragment.
  class NonElementParentNodeImpl {}

  exports = {
    implementation: NonElementParentNodeImpl
  };
  return exports;
}