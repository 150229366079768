import * as _whatwgUrl2 from "whatwg-url";

var _whatwgUrl = "default" in _whatwgUrl2 ? _whatwgUrl2.default : _whatwgUrl2;

import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _documentBaseUrlDew } from "../helpers/document-base-url";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const whatwgURL = _whatwgUrl;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const {
    fallbackBaseURL
  } = _documentBaseUrlDew();

  class HTMLBaseElementImpl extends HTMLElementImpl {
    get href() {
      const document = this._ownerDocument;
      const url = this.hasAttributeNS(null, "href") ? this.getAttributeNS(null, "href") : "";
      const parsed = whatwgURL.parseURL(url, {
        baseURL: fallbackBaseURL(document)
      });

      if (parsed === null) {
        return url;
      }

      return whatwgURL.serializeURL(parsed);
    }

    set href(value) {
      this.setAttributeNS(null, "href", value);
    }

  }

  exports = {
    implementation: HTMLBaseElementImpl
  };
  return exports;
}