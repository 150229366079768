import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _HTMLOutputElementImplDew } from "../nodes/HTMLOutputElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const HTMLElement = _HTMLElementDew();

  const interfaceName = "HTMLOutputElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLOutputElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLOutputElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLOutputElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      checkValidity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'checkValidity' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return esValue[implSymbol].checkValidity();
      }

      reportValidity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'reportValidity' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return esValue[implSymbol].reportValidity();
      }

      setCustomValidity(error) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'setCustomValidity' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'setCustomValidity' on 'HTMLOutputElement': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'setCustomValidity' on 'HTMLOutputElement': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].setCustomValidity(...args);
      }

      get htmlFor() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get htmlFor' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return utils.getSameObject(this, "htmlFor", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["htmlFor"]);
        });
      }

      set htmlFor(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set htmlFor' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        const Q = esValue["htmlFor"];

        if (!utils.isObject(Q)) {
          throw new globalObject.TypeError("Property 'htmlFor' is not an object");
        }

        Reflect.set(Q, "value", V);
      }

      get form() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get form' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["form"]);
      }

      get name() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get name' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "name");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set name(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set name' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'name' property on 'HTMLOutputElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "name", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get type() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get type' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return esValue[implSymbol]["type"];
      }

      get defaultValue() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get defaultValue' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["defaultValue"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set defaultValue(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set defaultValue' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'defaultValue' property on 'HTMLOutputElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["defaultValue"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get value() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get value' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["value"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set value(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set value' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'value' property on 'HTMLOutputElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["value"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get willValidate() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get willValidate' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return esValue[implSymbol]["willValidate"];
      }

      get validity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get validity' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["validity"]);
      }

      get validationMessage() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get validationMessage' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return esValue[implSymbol]["validationMessage"];
      }

      get labels() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get labels' called on an object that is not a valid instance of HTMLOutputElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["labels"]);
      }

    }

    Object.defineProperties(HTMLOutputElement.prototype, {
      checkValidity: {
        enumerable: true
      },
      reportValidity: {
        enumerable: true
      },
      setCustomValidity: {
        enumerable: true
      },
      htmlFor: {
        enumerable: true
      },
      form: {
        enumerable: true
      },
      name: {
        enumerable: true
      },
      type: {
        enumerable: true
      },
      defaultValue: {
        enumerable: true
      },
      value: {
        enumerable: true
      },
      willValidate: {
        enumerable: true
      },
      validity: {
        enumerable: true
      },
      validationMessage: {
        enumerable: true
      },
      labels: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLOutputElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLOutputElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLOutputElement
    });
  };

  const Impl = _HTMLOutputElementImplDew();

  return exports;
}