import { dew as _HTMLCollectionDew } from "../generated/HTMLCollection";
import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _DefaultConstraintValidationImplDew } from "../constraint-validation/DefaultConstraintValidation-impl";
import { dew as _formControlsDew } from "../helpers/form-controls";
import { dew as _utilsDew } from "../../utils";
import { dew as _traversalDew } from "../helpers/traversal";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLCollection = _HTMLCollectionDew();

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const DefaultConstraintValidationImpl = _DefaultConstraintValidationImplDew().implementation;

  const {
    formOwner
  } = _formControlsDew();

  const {
    mixin
  } = _utilsDew();

  const {
    descendantsByLocalNames
  } = _traversalDew();

  const listedElements = new Set(["button", "fieldset", "input", "object", "output", "select", "textarea"]);

  class HTMLFieldSetElementImpl extends HTMLElementImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, privateData);
      this._customValidityErrorMessage = "";
    }

    get elements() {
      return HTMLCollection.createImpl(this._globalObject, [], {
        element: this,
        query: () => descendantsByLocalNames(this, listedElements)
      });
    }

    get form() {
      return formOwner(this);
    }

    get type() {
      return "fieldset";
    }

    _barredFromConstraintValidationSpecialization() {
      return true;
    }

  }

  mixin(HTMLFieldSetElementImpl.prototype, DefaultConstraintValidationImpl.prototype);
  exports = {
    implementation: HTMLFieldSetElementImpl
  };
  return exports;
}