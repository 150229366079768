import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (typeof value !== "function") {
      throw new globalObject.TypeError(context + " is not a function");
    }

    function invokeTheCallbackFunction(blob) {
      const thisArg = utils.tryWrapperForImpl(this);
      let callResult;
      blob = utils.tryWrapperForImpl(blob);
      callResult = Reflect.apply(value, thisArg, [blob]);
    }

    invokeTheCallbackFunction.construct = blob => {
      blob = utils.tryWrapperForImpl(blob);
      let callResult = Reflect.construct(value, [blob]);
    };

    invokeTheCallbackFunction[utils.wrapperSymbol] = value;
    invokeTheCallbackFunction.objectReference = value;
    return invokeTheCallbackFunction;
  };

  return exports;
}