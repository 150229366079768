import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _EventModifierInitDew } from "./EventModifierInit.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const EventModifierInit = _EventModifierInitDew();

  exports._convertInherit = (globalObject, obj, ret, {
    context = "The provided value"
  } = {}) => {
    EventModifierInit._convertInherit(globalObject, obj, ret, {
      context
    });

    {
      const key = "changedTouches";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        if (!utils.isObject(value)) {
          throw new globalObject.TypeError(context + " has member 'changedTouches' that" + " is not an iterable object.");
        } else {
          const V = [];
          const tmp = value;

          for (let nextItem of tmp) {
            nextItem = utils.tryImplForWrapper(nextItem);
            V.push(nextItem);
          }

          value = V;
        }

        ret[key] = value;
      } else {
        ret[key] = [];
      }
    }
    {
      const key = "targetTouches";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        if (!utils.isObject(value)) {
          throw new globalObject.TypeError(context + " has member 'targetTouches' that" + " is not an iterable object.");
        } else {
          const V = [];
          const tmp = value;

          for (let nextItem of tmp) {
            nextItem = utils.tryImplForWrapper(nextItem);
            V.push(nextItem);
          }

          value = V;
        }

        ret[key] = value;
      } else {
        ret[key] = [];
      }
    }
    {
      const key = "touches";
      let value = obj === undefined || obj === null ? undefined : obj[key];

      if (value !== undefined) {
        if (!utils.isObject(value)) {
          throw new globalObject.TypeError(context + " has member 'touches' that" + " is not an iterable object.");
        } else {
          const V = [];
          const tmp = value;

          for (let nextItem of tmp) {
            nextItem = utils.tryImplForWrapper(nextItem);
            V.push(nextItem);
          }

          value = V;
        }

        ret[key] = value;
      } else {
        ret[key] = [];
      }
    }
  };

  exports.convert = (globalObject, obj, {
    context = "The provided value"
  } = {}) => {
    if (obj !== undefined && typeof obj !== "object" && typeof obj !== "function") {
      throw new globalObject.TypeError(`${context} is not an object.`);
    }

    const ret = Object.create(null);

    exports._convertInherit(globalObject, obj, ret, {
      context
    });

    return ret;
  };

  return exports;
}