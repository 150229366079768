import { dew as _utilsDew } from "../../utils";
import { dew as _NodeImplDew } from "./Node-impl";
import { dew as _ChildNodeImplDew } from "./ChildNode-impl";
import { dew as _nodeTypeDew } from "../node-type";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    mixin
  } = _utilsDew();

  const NodeImpl = _NodeImplDew().implementation;

  const ChildNodeImpl = _ChildNodeImplDew().implementation;

  const NODE_TYPE = _nodeTypeDew();

  class DocumentTypeImpl extends NodeImpl {
    constructor(globalObject, args, privateData) {
      super(globalObject, args, privateData);
      this.nodeType = NODE_TYPE.DOCUMENT_TYPE_NODE;
      this.name = privateData.name;
      this.publicId = privateData.publicId;
      this.systemId = privateData.systemId;
    }

  }

  mixin(DocumentTypeImpl.prototype, ChildNodeImpl.prototype);
  exports = {
    implementation: DocumentTypeImpl
  };
  return exports;
}