import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _KeyboardEventInitDew } from "./KeyboardEventInit.js";
import { dew as _UIEventDew } from "./UIEvent.js";
import { dew as _KeyboardEventImplDew } from "../events/KeyboardEvent-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const KeyboardEventInit = _KeyboardEventInitDew();

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const UIEvent = _UIEventDew();

  const interfaceName = "KeyboardEvent";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'KeyboardEvent'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["KeyboardEvent"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    UIEvent._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class KeyboardEvent extends globalObject.UIEvent {
      constructor(type) {
        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to construct 'KeyboardEvent': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to construct 'KeyboardEvent': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = KeyboardEventInit.convert(globalObject, curArg, {
            context: "Failed to construct 'KeyboardEvent': parameter 2"
          });
          args.push(curArg);
        }
        return exports.setup(Object.create(new.target.prototype), globalObject, args);
      }

      getModifierState(keyArg) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'getModifierState' called on an object that is not a valid instance of KeyboardEvent.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'getModifierState' on 'KeyboardEvent': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'getModifierState' on 'KeyboardEvent': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].getModifierState(...args);
      }

      initKeyboardEvent(typeArg) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'initKeyboardEvent' called on an object that is not a valid instance of KeyboardEvent.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];

          if (curArg !== undefined) {
            curArg = conversions["boolean"](curArg, {
              context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 2",
              globals: globalObject
            });
          } else {
            curArg = false;
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[2];

          if (curArg !== undefined) {
            curArg = conversions["boolean"](curArg, {
              context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 3",
              globals: globalObject
            });
          } else {
            curArg = false;
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[3];

          if (curArg !== undefined) {
            if (curArg === null || curArg === undefined) {
              curArg = null;
            } else {
              curArg = utils.tryImplForWrapper(curArg);
            }
          } else {
            curArg = null;
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[4];

          if (curArg !== undefined) {
            curArg = conversions["DOMString"](curArg, {
              context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 5",
              globals: globalObject
            });
          } else {
            curArg = "";
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[5];

          if (curArg !== undefined) {
            curArg = conversions["unsigned long"](curArg, {
              context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 6",
              globals: globalObject
            });
          } else {
            curArg = 0;
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[6];

          if (curArg !== undefined) {
            curArg = conversions["boolean"](curArg, {
              context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 7",
              globals: globalObject
            });
          } else {
            curArg = false;
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[7];

          if (curArg !== undefined) {
            curArg = conversions["boolean"](curArg, {
              context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 8",
              globals: globalObject
            });
          } else {
            curArg = false;
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[8];

          if (curArg !== undefined) {
            curArg = conversions["boolean"](curArg, {
              context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 9",
              globals: globalObject
            });
          } else {
            curArg = false;
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[9];

          if (curArg !== undefined) {
            curArg = conversions["boolean"](curArg, {
              context: "Failed to execute 'initKeyboardEvent' on 'KeyboardEvent': parameter 10",
              globals: globalObject
            });
          } else {
            curArg = false;
          }

          args.push(curArg);
        }
        return esValue[implSymbol].initKeyboardEvent(...args);
      }

      get key() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get key' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["key"];
      }

      get code() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get code' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["code"];
      }

      get location() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get location' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["location"];
      }

      get ctrlKey() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get ctrlKey' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["ctrlKey"];
      }

      get shiftKey() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get shiftKey' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["shiftKey"];
      }

      get altKey() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get altKey' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["altKey"];
      }

      get metaKey() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get metaKey' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["metaKey"];
      }

      get repeat() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get repeat' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["repeat"];
      }

      get isComposing() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get isComposing' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["isComposing"];
      }

      get charCode() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get charCode' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["charCode"];
      }

      get keyCode() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get keyCode' called on an object that is not a valid instance of KeyboardEvent.");
        }

        return esValue[implSymbol]["keyCode"];
      }

    }

    Object.defineProperties(KeyboardEvent.prototype, {
      getModifierState: {
        enumerable: true
      },
      initKeyboardEvent: {
        enumerable: true
      },
      key: {
        enumerable: true
      },
      code: {
        enumerable: true
      },
      location: {
        enumerable: true
      },
      ctrlKey: {
        enumerable: true
      },
      shiftKey: {
        enumerable: true
      },
      altKey: {
        enumerable: true
      },
      metaKey: {
        enumerable: true
      },
      repeat: {
        enumerable: true
      },
      isComposing: {
        enumerable: true
      },
      charCode: {
        enumerable: true
      },
      keyCode: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "KeyboardEvent",
        configurable: true
      },
      DOM_KEY_LOCATION_STANDARD: {
        value: 0,
        enumerable: true
      },
      DOM_KEY_LOCATION_LEFT: {
        value: 1,
        enumerable: true
      },
      DOM_KEY_LOCATION_RIGHT: {
        value: 2,
        enumerable: true
      },
      DOM_KEY_LOCATION_NUMPAD: {
        value: 3,
        enumerable: true
      }
    });
    Object.defineProperties(KeyboardEvent, {
      DOM_KEY_LOCATION_STANDARD: {
        value: 0,
        enumerable: true
      },
      DOM_KEY_LOCATION_LEFT: {
        value: 1,
        enumerable: true
      },
      DOM_KEY_LOCATION_RIGHT: {
        value: 2,
        enumerable: true
      },
      DOM_KEY_LOCATION_NUMPAD: {
        value: 3,
        enumerable: true
      }
    });
    ctorRegistry[interfaceName] = KeyboardEvent;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: KeyboardEvent
    });
  };

  const Impl = _KeyboardEventImplDew();

  return exports;
}