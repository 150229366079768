import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  class HTMLTrackElementImpl extends HTMLElementImpl {
    get readyState() {
      return 0;
    }

  }

  exports = {
    implementation: HTMLTrackElementImpl
  };
  return exports;
}