import { dew as _DocumentImplDew } from "./Document-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const DocumentImpl = _DocumentImplDew().implementation;

  exports.implementation = class XMLDocumentImpl extends DocumentImpl {};
  return exports;
}