import { dew as _styleDew } from "../level2/style";
import { dew as _xpathDew } from "../level3/xpath";
import * as _webidl2jsWrapper2 from "domexception/webidl2js-wrapper";

var _webidl2jsWrapper = "default" in _webidl2jsWrapper2 ? _webidl2jsWrapper2.default : _webidl2jsWrapper2;

import * as _webidl2jsWrapper4 from "whatwg-url/webidl2js-wrapper";

var _webidl2jsWrapper3 = "default" in _webidl2jsWrapper4 ? _webidl2jsWrapper4.default : _webidl2jsWrapper4;

import { dew as _EventTargetDew } from "./generated/EventTarget";
import { dew as _NamedNodeMapDew } from "./generated/NamedNodeMap";
import { dew as _NodeDew } from "./generated/Node";
import { dew as _AttrDew } from "./generated/Attr";
import { dew as _ElementDew } from "./generated/Element";
import { dew as _DocumentFragmentDew } from "./generated/DocumentFragment";
import { dew as _DOMImplementationDew } from "./generated/DOMImplementation";
import { dew as _DocumentDew } from "./generated/Document";
import { dew as _XMLDocumentDew } from "./generated/XMLDocument";
import { dew as _CharacterDataDew } from "./generated/CharacterData";
import { dew as _TextDew } from "./generated/Text";
import { dew as _CDATASectionDew } from "./generated/CDATASection";
import { dew as _ProcessingInstructionDew } from "./generated/ProcessingInstruction";
import { dew as _CommentDew } from "./generated/Comment";
import { dew as _DocumentTypeDew } from "./generated/DocumentType";
import { dew as _NodeListDew } from "./generated/NodeList";
import { dew as _RadioNodeListDew } from "./generated/RadioNodeList";
import { dew as _HTMLCollectionDew } from "./generated/HTMLCollection";
import { dew as _HTMLOptionsCollectionDew } from "./generated/HTMLOptionsCollection";
import { dew as _DOMStringMapDew } from "./generated/DOMStringMap";
import { dew as _DOMTokenListDew } from "./generated/DOMTokenList";
import { dew as _StyleSheetListDew } from "./generated/StyleSheetList.js";
import { dew as _HTMLElementDew } from "./generated/HTMLElement.js";
import { dew as _HTMLHeadElementDew } from "./generated/HTMLHeadElement.js";
import { dew as _HTMLTitleElementDew } from "./generated/HTMLTitleElement.js";
import { dew as _HTMLBaseElementDew } from "./generated/HTMLBaseElement.js";
import { dew as _HTMLLinkElementDew } from "./generated/HTMLLinkElement.js";
import { dew as _HTMLMetaElementDew } from "./generated/HTMLMetaElement.js";
import { dew as _HTMLStyleElementDew } from "./generated/HTMLStyleElement.js";
import { dew as _HTMLBodyElementDew } from "./generated/HTMLBodyElement.js";
import { dew as _HTMLHeadingElementDew } from "./generated/HTMLHeadingElement.js";
import { dew as _HTMLParagraphElementDew } from "./generated/HTMLParagraphElement.js";
import { dew as _HTMLHRElementDew } from "./generated/HTMLHRElement.js";
import { dew as _HTMLPreElementDew } from "./generated/HTMLPreElement.js";
import { dew as _HTMLUListElementDew } from "./generated/HTMLUListElement.js";
import { dew as _HTMLOListElementDew } from "./generated/HTMLOListElement.js";
import { dew as _HTMLLIElementDew } from "./generated/HTMLLIElement.js";
import { dew as _HTMLMenuElementDew } from "./generated/HTMLMenuElement.js";
import { dew as _HTMLDListElementDew } from "./generated/HTMLDListElement.js";
import { dew as _HTMLDivElementDew } from "./generated/HTMLDivElement.js";
import { dew as _HTMLAnchorElementDew } from "./generated/HTMLAnchorElement.js";
import { dew as _HTMLAreaElementDew } from "./generated/HTMLAreaElement.js";
import { dew as _HTMLBRElementDew } from "./generated/HTMLBRElement.js";
import { dew as _HTMLButtonElementDew } from "./generated/HTMLButtonElement.js";
import { dew as _HTMLCanvasElementDew } from "./generated/HTMLCanvasElement.js";
import { dew as _HTMLDataElementDew } from "./generated/HTMLDataElement.js";
import { dew as _HTMLDataListElementDew } from "./generated/HTMLDataListElement.js";
import { dew as _HTMLDetailsElementDew } from "./generated/HTMLDetailsElement.js";
import { dew as _HTMLDialogElementDew } from "./generated/HTMLDialogElement.js";
import { dew as _HTMLDirectoryElementDew } from "./generated/HTMLDirectoryElement.js";
import { dew as _HTMLFieldSetElementDew } from "./generated/HTMLFieldSetElement.js";
import { dew as _HTMLFontElementDew } from "./generated/HTMLFontElement.js";
import { dew as _HTMLFormElementDew } from "./generated/HTMLFormElement.js";
import { dew as _HTMLHtmlElementDew } from "./generated/HTMLHtmlElement.js";
import { dew as _HTMLImageElementDew } from "./generated/HTMLImageElement.js";
import { dew as _HTMLInputElementDew } from "./generated/HTMLInputElement.js";
import { dew as _HTMLLabelElementDew } from "./generated/HTMLLabelElement.js";
import { dew as _HTMLLegendElementDew } from "./generated/HTMLLegendElement.js";
import { dew as _HTMLMapElementDew } from "./generated/HTMLMapElement.js";
import { dew as _HTMLMarqueeElementDew } from "./generated/HTMLMarqueeElement.js";
import { dew as _HTMLMediaElementDew } from "./generated/HTMLMediaElement.js";
import { dew as _HTMLMeterElementDew } from "./generated/HTMLMeterElement.js";
import { dew as _HTMLModElementDew } from "./generated/HTMLModElement.js";
import { dew as _HTMLOptGroupElementDew } from "./generated/HTMLOptGroupElement.js";
import { dew as _HTMLOptionElementDew } from "./generated/HTMLOptionElement.js";
import { dew as _HTMLOutputElementDew } from "./generated/HTMLOutputElement.js";
import { dew as _HTMLPictureElementDew } from "./generated/HTMLPictureElement.js";
import { dew as _HTMLProgressElementDew } from "./generated/HTMLProgressElement.js";
import { dew as _HTMLQuoteElementDew } from "./generated/HTMLQuoteElement.js";
import { dew as _HTMLScriptElementDew } from "./generated/HTMLScriptElement.js";
import { dew as _HTMLSelectElementDew } from "./generated/HTMLSelectElement.js";
import { dew as _HTMLSlotElementDew } from "./generated/HTMLSlotElement.js";
import { dew as _HTMLSourceElementDew } from "./generated/HTMLSourceElement.js";
import { dew as _HTMLSpanElementDew } from "./generated/HTMLSpanElement.js";
import { dew as _HTMLTableCaptionElementDew } from "./generated/HTMLTableCaptionElement.js";
import { dew as _HTMLTableCellElementDew } from "./generated/HTMLTableCellElement.js";
import { dew as _HTMLTableColElementDew } from "./generated/HTMLTableColElement.js";
import { dew as _HTMLTableElementDew } from "./generated/HTMLTableElement.js";
import { dew as _HTMLTimeElementDew } from "./generated/HTMLTimeElement.js";
import { dew as _HTMLTableRowElementDew } from "./generated/HTMLTableRowElement.js";
import { dew as _HTMLTableSectionElementDew } from "./generated/HTMLTableSectionElement.js";
import { dew as _HTMLTemplateElementDew } from "./generated/HTMLTemplateElement.js";
import { dew as _HTMLTextAreaElementDew } from "./generated/HTMLTextAreaElement.js";
import { dew as _HTMLUnknownElementDew } from "./generated/HTMLUnknownElement.js";
import { dew as _HTMLFrameElementDew } from "./generated/HTMLFrameElement.js";
import { dew as _HTMLFrameSetElementDew } from "./generated/HTMLFrameSetElement.js";
import { dew as _HTMLIFrameElementDew } from "./generated/HTMLIFrameElement.js";
import { dew as _HTMLEmbedElementDew } from "./generated/HTMLEmbedElement.js";
import { dew as _HTMLObjectElementDew } from "./generated/HTMLObjectElement.js";
import { dew as _HTMLParamElementDew } from "./generated/HTMLParamElement.js";
import { dew as _HTMLVideoElementDew } from "./generated/HTMLVideoElement.js";
import { dew as _HTMLAudioElementDew } from "./generated/HTMLAudioElement.js";
import { dew as _HTMLTrackElementDew } from "./generated/HTMLTrackElement.js";
import { dew as _HTMLFormControlsCollectionDew } from "./generated/HTMLFormControlsCollection.js";
import { dew as _SVGElementDew } from "./generated/SVGElement.js";
import { dew as _SVGGraphicsElementDew } from "./generated/SVGGraphicsElement.js";
import { dew as _SVGSVGElementDew } from "./generated/SVGSVGElement.js";
import { dew as _SVGTitleElementDew } from "./generated/SVGTitleElement.js";
import { dew as _SVGAnimatedStringDew } from "./generated/SVGAnimatedString";
import { dew as _SVGNumberDew } from "./generated/SVGNumber";
import { dew as _SVGStringListDew } from "./generated/SVGStringList";
import { dew as _EventDew } from "./generated/Event";
import { dew as _CloseEventDew } from "./generated/CloseEvent";
import { dew as _CustomEventDew } from "./generated/CustomEvent";
import { dew as _MessageEventDew } from "./generated/MessageEvent";
import { dew as _ErrorEventDew } from "./generated/ErrorEvent";
import { dew as _HashChangeEventDew } from "./generated/HashChangeEvent";
import { dew as _PopStateEventDew } from "./generated/PopStateEvent";
import { dew as _StorageEventDew } from "./generated/StorageEvent";
import { dew as _ProgressEventDew } from "./generated/ProgressEvent";
import { dew as _PageTransitionEventDew } from "./generated/PageTransitionEvent";
import { dew as _UIEventDew } from "./generated/UIEvent";
import { dew as _FocusEventDew } from "./generated/FocusEvent";
import { dew as _InputEventDew } from "./generated/InputEvent";
import { dew as _MouseEventDew } from "./generated/MouseEvent";
import { dew as _KeyboardEventDew } from "./generated/KeyboardEvent";
import { dew as _TouchEventDew } from "./generated/TouchEvent";
import { dew as _CompositionEventDew } from "./generated/CompositionEvent";
import { dew as _WheelEventDew } from "./generated/WheelEvent";
import { dew as _BarPropDew } from "./generated/BarProp";
import { dew as _ExternalDew } from "./generated/External";
import { dew as _LocationDew } from "./generated/Location";
import { dew as _HistoryDew } from "./generated/History";
import { dew as _ScreenDew } from "./generated/Screen";
import { dew as _PerformanceDew } from "./generated/Performance";
import { dew as _NavigatorDew } from "./generated/Navigator";
import { dew as _CryptoDew } from "./generated/Crypto";
import { dew as _PluginArrayDew } from "./generated/PluginArray";
import { dew as _MimeTypeArrayDew } from "./generated/MimeTypeArray";
import { dew as _PluginDew } from "./generated/Plugin";
import { dew as _MimeTypeDew } from "./generated/MimeType";
import { dew as _FileReaderDew } from "./generated/FileReader";
import { dew as _BlobDew } from "./generated/Blob";
import { dew as _FileDew } from "./generated/File";
import { dew as _FileListDew } from "./generated/FileList";
import { dew as _ValidityStateDew } from "./generated/ValidityState";
import { dew as _DOMParserDew } from "./generated/DOMParser";
import { dew as _XMLSerializerDew } from "./generated/XMLSerializer";
import { dew as _FormDataDew } from "./generated/FormData";
import { dew as _XMLHttpRequestEventTargetDew } from "./generated/XMLHttpRequestEventTarget";
import { dew as _XMLHttpRequestUploadDew } from "./generated/XMLHttpRequestUpload";
import { dew as _XMLHttpRequestDew } from "./generated/XMLHttpRequest";
import { dew as _WebSocketDew } from "./generated/WebSocket";
import { dew as _NodeFilterDew } from "./generated/NodeFilter";
import { dew as _NodeIteratorDew } from "./generated/NodeIterator";
import { dew as _TreeWalkerDew } from "./generated/TreeWalker";
import { dew as _AbstractRangeDew } from "./generated/AbstractRange";
import { dew as _RangeDew } from "./generated/Range";
import { dew as _StaticRangeDew } from "./generated/StaticRange";
import { dew as _SelectionDew } from "./generated/Selection";
import { dew as _StorageDew } from "./generated/Storage";
import { dew as _CustomElementRegistryDew } from "./generated/CustomElementRegistry";
import { dew as _ShadowRootDew } from "./generated/ShadowRoot";
import { dew as _MutationObserverDew } from "./generated/MutationObserver";
import { dew as _MutationRecordDew } from "./generated/MutationRecord";
import { dew as _HeadersDew } from "./generated/Headers";
import { dew as _AbortControllerDew } from "./generated/AbortController";
import { dew as _AbortSignalDew } from "./generated/AbortSignal";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const style = _styleDew();

  const xpath = _xpathDew(); // This object defines the mapping between the interface name and the generated interface wrapper code.
  // Note: The mapping needs to stay as-is in order due to interface evaluation.
  // We cannot "refactor" this to something less duplicative because that would break bundlers which depend on static
  // analysis of require()s.


  const generatedInterfaces = {
    DOMException: _webidl2jsWrapper,
    URL: _webidl2jsWrapper3.URL,
    URLSearchParams: _webidl2jsWrapper3.URLSearchParams,
    EventTarget: _EventTargetDew(),
    NamedNodeMap: _NamedNodeMapDew(),
    Node: _NodeDew(),
    Attr: _AttrDew(),
    Element: _ElementDew(),
    DocumentFragment: _DocumentFragmentDew(),
    DOMImplementation: _DOMImplementationDew(),
    Document: _DocumentDew(),
    XMLDocument: _XMLDocumentDew(),
    CharacterData: _CharacterDataDew(),
    Text: _TextDew(),
    CDATASection: _CDATASectionDew(),
    ProcessingInstruction: _ProcessingInstructionDew(),
    Comment: _CommentDew(),
    DocumentType: _DocumentTypeDew(),
    NodeList: _NodeListDew(),
    RadioNodeList: _RadioNodeListDew(),
    HTMLCollection: _HTMLCollectionDew(),
    HTMLOptionsCollection: _HTMLOptionsCollectionDew(),
    DOMStringMap: _DOMStringMapDew(),
    DOMTokenList: _DOMTokenListDew(),
    StyleSheetList: _StyleSheetListDew(),
    HTMLElement: _HTMLElementDew(),
    HTMLHeadElement: _HTMLHeadElementDew(),
    HTMLTitleElement: _HTMLTitleElementDew(),
    HTMLBaseElement: _HTMLBaseElementDew(),
    HTMLLinkElement: _HTMLLinkElementDew(),
    HTMLMetaElement: _HTMLMetaElementDew(),
    HTMLStyleElement: _HTMLStyleElementDew(),
    HTMLBodyElement: _HTMLBodyElementDew(),
    HTMLHeadingElement: _HTMLHeadingElementDew(),
    HTMLParagraphElement: _HTMLParagraphElementDew(),
    HTMLHRElement: _HTMLHRElementDew(),
    HTMLPreElement: _HTMLPreElementDew(),
    HTMLUListElement: _HTMLUListElementDew(),
    HTMLOListElement: _HTMLOListElementDew(),
    HTMLLIElement: _HTMLLIElementDew(),
    HTMLMenuElement: _HTMLMenuElementDew(),
    HTMLDListElement: _HTMLDListElementDew(),
    HTMLDivElement: _HTMLDivElementDew(),
    HTMLAnchorElement: _HTMLAnchorElementDew(),
    HTMLAreaElement: _HTMLAreaElementDew(),
    HTMLBRElement: _HTMLBRElementDew(),
    HTMLButtonElement: _HTMLButtonElementDew(),
    HTMLCanvasElement: _HTMLCanvasElementDew(),
    HTMLDataElement: _HTMLDataElementDew(),
    HTMLDataListElement: _HTMLDataListElementDew(),
    HTMLDetailsElement: _HTMLDetailsElementDew(),
    HTMLDialogElement: _HTMLDialogElementDew(),
    HTMLDirectoryElement: _HTMLDirectoryElementDew(),
    HTMLFieldSetElement: _HTMLFieldSetElementDew(),
    HTMLFontElement: _HTMLFontElementDew(),
    HTMLFormElement: _HTMLFormElementDew(),
    HTMLHtmlElement: _HTMLHtmlElementDew(),
    HTMLImageElement: _HTMLImageElementDew(),
    HTMLInputElement: _HTMLInputElementDew(),
    HTMLLabelElement: _HTMLLabelElementDew(),
    HTMLLegendElement: _HTMLLegendElementDew(),
    HTMLMapElement: _HTMLMapElementDew(),
    HTMLMarqueeElement: _HTMLMarqueeElementDew(),
    HTMLMediaElement: _HTMLMediaElementDew(),
    HTMLMeterElement: _HTMLMeterElementDew(),
    HTMLModElement: _HTMLModElementDew(),
    HTMLOptGroupElement: _HTMLOptGroupElementDew(),
    HTMLOptionElement: _HTMLOptionElementDew(),
    HTMLOutputElement: _HTMLOutputElementDew(),
    HTMLPictureElement: _HTMLPictureElementDew(),
    HTMLProgressElement: _HTMLProgressElementDew(),
    HTMLQuoteElement: _HTMLQuoteElementDew(),
    HTMLScriptElement: _HTMLScriptElementDew(),
    HTMLSelectElement: _HTMLSelectElementDew(),
    HTMLSlotElement: _HTMLSlotElementDew(),
    HTMLSourceElement: _HTMLSourceElementDew(),
    HTMLSpanElement: _HTMLSpanElementDew(),
    HTMLTableCaptionElement: _HTMLTableCaptionElementDew(),
    HTMLTableCellElement: _HTMLTableCellElementDew(),
    HTMLTableColElement: _HTMLTableColElementDew(),
    HTMLTableElement: _HTMLTableElementDew(),
    HTMLTimeElement: _HTMLTimeElementDew(),
    HTMLTableRowElement: _HTMLTableRowElementDew(),
    HTMLTableSectionElement: _HTMLTableSectionElementDew(),
    HTMLTemplateElement: _HTMLTemplateElementDew(),
    HTMLTextAreaElement: _HTMLTextAreaElementDew(),
    HTMLUnknownElement: _HTMLUnknownElementDew(),
    HTMLFrameElement: _HTMLFrameElementDew(),
    HTMLFrameSetElement: _HTMLFrameSetElementDew(),
    HTMLIFrameElement: _HTMLIFrameElementDew(),
    HTMLEmbedElement: _HTMLEmbedElementDew(),
    HTMLObjectElement: _HTMLObjectElementDew(),
    HTMLParamElement: _HTMLParamElementDew(),
    HTMLVideoElement: _HTMLVideoElementDew(),
    HTMLAudioElement: _HTMLAudioElementDew(),
    HTMLTrackElement: _HTMLTrackElementDew(),
    HTMLFormControlsCollection: _HTMLFormControlsCollectionDew(),
    SVGElement: _SVGElementDew(),
    SVGGraphicsElement: _SVGGraphicsElementDew(),
    SVGSVGElement: _SVGSVGElementDew(),
    SVGTitleElement: _SVGTitleElementDew(),
    SVGAnimatedString: _SVGAnimatedStringDew(),
    SVGNumber: _SVGNumberDew(),
    SVGStringList: _SVGStringListDew(),
    Event: _EventDew(),
    CloseEvent: _CloseEventDew(),
    CustomEvent: _CustomEventDew(),
    MessageEvent: _MessageEventDew(),
    ErrorEvent: _ErrorEventDew(),
    HashChangeEvent: _HashChangeEventDew(),
    PopStateEvent: _PopStateEventDew(),
    StorageEvent: _StorageEventDew(),
    ProgressEvent: _ProgressEventDew(),
    PageTransitionEvent: _PageTransitionEventDew(),
    UIEvent: _UIEventDew(),
    FocusEvent: _FocusEventDew(),
    InputEvent: _InputEventDew(),
    MouseEvent: _MouseEventDew(),
    KeyboardEvent: _KeyboardEventDew(),
    TouchEvent: _TouchEventDew(),
    CompositionEvent: _CompositionEventDew(),
    WheelEvent: _WheelEventDew(),
    BarProp: _BarPropDew(),
    External: _ExternalDew(),
    Location: _LocationDew(),
    History: _HistoryDew(),
    Screen: _ScreenDew(),
    Performance: _PerformanceDew(),
    Navigator: _NavigatorDew(),
    Crypto: _CryptoDew(),
    PluginArray: _PluginArrayDew(),
    MimeTypeArray: _MimeTypeArrayDew(),
    Plugin: _PluginDew(),
    MimeType: _MimeTypeDew(),
    FileReader: _FileReaderDew(),
    Blob: _BlobDew(),
    File: _FileDew(),
    FileList: _FileListDew(),
    ValidityState: _ValidityStateDew(),
    DOMParser: _DOMParserDew(),
    XMLSerializer: _XMLSerializerDew(),
    FormData: _FormDataDew(),
    XMLHttpRequestEventTarget: _XMLHttpRequestEventTargetDew(),
    XMLHttpRequestUpload: _XMLHttpRequestUploadDew(),
    XMLHttpRequest: _XMLHttpRequestDew(),
    WebSocket: _WebSocketDew(),
    NodeFilter: _NodeFilterDew(),
    NodeIterator: _NodeIteratorDew(),
    TreeWalker: _TreeWalkerDew(),
    AbstractRange: _AbstractRangeDew(),
    Range: _RangeDew(),
    StaticRange: _StaticRangeDew(),
    Selection: _SelectionDew(),
    Storage: _StorageDew(),
    CustomElementRegistry: _CustomElementRegistryDew(),
    ShadowRoot: _ShadowRootDew(),
    MutationObserver: _MutationObserverDew(),
    MutationRecord: _MutationRecordDew(),
    Headers: _HeadersDew(),
    AbortController: _AbortControllerDew(),
    AbortSignal: _AbortSignalDew()
  };

  function install(window, name, interfaceConstructor) {
    Object.defineProperty(window, name, {
      configurable: true,
      writable: true,
      value: interfaceConstructor
    });
  }

  exports.installInterfaces = (window, globalNames) => {
    // Install generated interface.
    for (const generatedInterface of Object.values(generatedInterfaces)) {
      generatedInterface.install(window, globalNames);
    } // Install legacy HTMLDocument interface
    // https://html.spec.whatwg.org/#htmldocument


    install(window, "HTMLDocument", window.Document); // These need to be cleaned up...

    style.addToCore(window);
    xpath(window);
  }; // Returns an interface webidl2js wrapper given its an interface name.


  exports.getInterfaceWrapper = name => {
    return generatedInterfaces[name];
  };

  return exports;
}