import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _HTMLOptionElementDew } from "./HTMLOptionElement.js";
import { dew as _HTMLOptGroupElementDew } from "./HTMLOptGroupElement.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _stringsDew } from "../helpers/strings.js";
import { dew as _HTMLSelectElementImplDew } from "../nodes/HTMLSelectElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const HTMLOptionElement = _HTMLOptionElementDew();

  const HTMLOptGroupElement = _HTMLOptGroupElementDew();

  const HTMLElement = _HTMLElementDew();

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const parseNonNegativeInteger_helpers_strings = _stringsDew().parseNonNegativeInteger;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;
  const interfaceName = "HTMLSelectElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLSelectElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLSelectElement"].prototype;
    }

    return Object.create(proto);
  }

  function makeProxy(wrapper, globalObject) {
    let proxyHandler = proxyHandlerCache.get(globalObject);

    if (proxyHandler === undefined) {
      proxyHandler = new ProxyHandler(globalObject);
      proxyHandlerCache.set(globalObject, proxyHandler);
    }

    return new Proxy(wrapper, proxyHandler);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper = makeProxy(wrapper, globalObject);
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    let wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper = makeProxy(wrapper, globalObject);
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLSelectElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      item(index) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'item' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'item' on 'HTMLSelectElement': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["unsigned long"](curArg, {
            context: "Failed to execute 'item' on 'HTMLSelectElement': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].item(...args));
      }

      namedItem(name) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'namedItem' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'namedItem' on 'HTMLSelectElement': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'namedItem' on 'HTMLSelectElement': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return utils.tryWrapperForImpl(esValue[implSymbol].namedItem(...args));
      }

      add(element) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'add' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'add' on 'HTMLSelectElement': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (HTMLOptionElement.is(curArg) || HTMLOptGroupElement.is(curArg)) {
            curArg = utils.implForWrapper(curArg);
          } else {
            throw new globalObject.TypeError("Failed to execute 'add' on 'HTMLSelectElement': parameter 1" + " is not of any supported type.");
          }

          args.push(curArg);
        }
        {
          let curArg = arguments[1];

          if (curArg !== undefined) {
            if (curArg === null || curArg === undefined) {
              curArg = null;
            } else {
              if (HTMLElement.is(curArg)) {
                curArg = utils.implForWrapper(curArg);
              } else if (typeof curArg === "number") {
                curArg = conversions["long"](curArg, {
                  context: "Failed to execute 'add' on 'HTMLSelectElement': parameter 2",
                  globals: globalObject
                });
              } else {
                curArg = conversions["long"](curArg, {
                  context: "Failed to execute 'add' on 'HTMLSelectElement': parameter 2",
                  globals: globalObject
                });
              }
            }
          } else {
            curArg = null;
          }

          args.push(curArg);
        }
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].add(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      remove() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'remove' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        const args = [];

        switch (arguments.length) {
          case 0:
            break;

          default:
            {
              let curArg = arguments[0];
              curArg = conversions["long"](curArg, {
                context: "Failed to execute 'remove' on 'HTMLSelectElement': parameter 1",
                globals: globalObject
              });
              args.push(curArg);
            }
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].remove(...args);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      checkValidity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'checkValidity' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return esValue[implSymbol].checkValidity();
      }

      reportValidity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'reportValidity' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return esValue[implSymbol].reportValidity();
      }

      setCustomValidity(error) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'setCustomValidity' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        if (arguments.length < 1) {
          throw new globalObject.TypeError(`Failed to execute 'setCustomValidity' on 'HTMLSelectElement': 1 argument required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'setCustomValidity' on 'HTMLSelectElement': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        return esValue[implSymbol].setCustomValidity(...args);
      }

      get autofocus() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get autofocus' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "autofocus");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set autofocus(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set autofocus' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'autofocus' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "autofocus", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "autofocus");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get disabled() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get disabled' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "disabled");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set disabled(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set disabled' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'disabled' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "disabled", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "disabled");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get form() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get form' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["form"]);
      }

      get multiple() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get multiple' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "multiple");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set multiple(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set multiple' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'multiple' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "multiple", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "multiple");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get name() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get name' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "name");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set name(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set name' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'name' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "name", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get required() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get required' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "required");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set required(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set required' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'required' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "required", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "required");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get size() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get size' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "size");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set size(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set size' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'size' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "size", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get type() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get type' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return esValue[implSymbol]["type"];
      }

      get options() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get options' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return utils.getSameObject(this, "options", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["options"]);
        });
      }

      get length() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get length' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["length"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set length(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set length' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'length' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["length"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get selectedOptions() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get selectedOptions' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return utils.getSameObject(this, "selectedOptions", () => {
          return utils.tryWrapperForImpl(esValue[implSymbol]["selectedOptions"]);
        });
      }

      get selectedIndex() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get selectedIndex' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return esValue[implSymbol]["selectedIndex"];
      }

      set selectedIndex(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set selectedIndex' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["long"](V, {
          context: "Failed to set the 'selectedIndex' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        esValue[implSymbol]["selectedIndex"] = V;
      }

      get value() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get value' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return esValue[implSymbol]["value"];
      }

      set value(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set value' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'value' property on 'HTMLSelectElement': The provided value",
          globals: globalObject
        });
        esValue[implSymbol]["value"] = V;
      }

      get willValidate() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get willValidate' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return esValue[implSymbol]["willValidate"];
      }

      get validity() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get validity' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["validity"]);
      }

      get validationMessage() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get validationMessage' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return esValue[implSymbol]["validationMessage"];
      }

      get labels() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get labels' called on an object that is not a valid instance of HTMLSelectElement.");
        }

        return utils.tryWrapperForImpl(esValue[implSymbol]["labels"]);
      }

    }

    Object.defineProperties(HTMLSelectElement.prototype, {
      item: {
        enumerable: true
      },
      namedItem: {
        enumerable: true
      },
      add: {
        enumerable: true
      },
      remove: {
        enumerable: true
      },
      checkValidity: {
        enumerable: true
      },
      reportValidity: {
        enumerable: true
      },
      setCustomValidity: {
        enumerable: true
      },
      autofocus: {
        enumerable: true
      },
      disabled: {
        enumerable: true
      },
      form: {
        enumerable: true
      },
      multiple: {
        enumerable: true
      },
      name: {
        enumerable: true
      },
      required: {
        enumerable: true
      },
      size: {
        enumerable: true
      },
      type: {
        enumerable: true
      },
      options: {
        enumerable: true
      },
      length: {
        enumerable: true
      },
      selectedOptions: {
        enumerable: true
      },
      selectedIndex: {
        enumerable: true
      },
      value: {
        enumerable: true
      },
      willValidate: {
        enumerable: true
      },
      validity: {
        enumerable: true
      },
      validationMessage: {
        enumerable: true
      },
      labels: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLSelectElement",
        configurable: true
      },
      [Symbol.iterator]: {
        value: globalObject.Array.prototype[Symbol.iterator],
        configurable: true,
        writable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLSelectElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLSelectElement
    });
  };

  const proxyHandlerCache = new WeakMap();

  class ProxyHandler {
    constructor(globalObject) {
      this._globalObject = globalObject;
    }

    get(target, P, receiver) {
      if (typeof P === "symbol") {
        return Reflect.get(target, P, receiver);
      }

      const desc = this.getOwnPropertyDescriptor(target, P);

      if (desc === undefined) {
        const parent = Object.getPrototypeOf(target);

        if (parent === null) {
          return undefined;
        }

        return Reflect.get(target, P, receiver);
      }

      if (!desc.get && !desc.set) {
        return desc.value;
      }

      const getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return Reflect.apply(getter, receiver, []);
    }

    has(target, P) {
      if (typeof P === "symbol") {
        return Reflect.has(target, P);
      }

      const desc = this.getOwnPropertyDescriptor(target, P);

      if (desc !== undefined) {
        return true;
      }

      const parent = Object.getPrototypeOf(target);

      if (parent !== null) {
        return Reflect.has(parent, P);
      }

      return false;
    }

    ownKeys(target) {
      const keys = new Set();

      for (const key of target[implSymbol][utils.supportedPropertyIndices]) {
        keys.add(`${key}`);
      }

      for (const key of Reflect.ownKeys(target)) {
        keys.add(key);
      }

      return [...keys];
    }

    getOwnPropertyDescriptor(target, P) {
      if (typeof P === "symbol") {
        return Reflect.getOwnPropertyDescriptor(target, P);
      }

      let ignoreNamedProps = false;

      if (utils.isArrayIndexPropName(P)) {
        const index = P >>> 0;
        const indexedValue = target[implSymbol].item(index);

        if (indexedValue !== null) {
          return {
            writable: true,
            enumerable: true,
            configurable: true,
            value: utils.tryWrapperForImpl(indexedValue)
          };
        }

        ignoreNamedProps = true;
      }

      return Reflect.getOwnPropertyDescriptor(target, P);
    }

    set(target, P, V, receiver) {
      if (typeof P === "symbol") {
        return Reflect.set(target, P, V, receiver);
      } // The `receiver` argument refers to the Proxy exotic object or an object
      // that inherits from it, whereas `target` refers to the Proxy target:


      if (target[implSymbol][utils.wrapperSymbol] === receiver) {
        const globalObject = this._globalObject;

        if (utils.isArrayIndexPropName(P)) {
          const index = P >>> 0;
          let indexedValue = V;

          if (indexedValue === null || indexedValue === undefined) {
            indexedValue = null;
          } else {
            indexedValue = HTMLOptionElement.convert(globalObject, indexedValue, {
              context: "Failed to set the " + index + " property on 'HTMLSelectElement': The provided value"
            });
          }

          ceReactionsPreSteps_helpers_custom_elements(globalObject);

          try {
            const creating = !(target[implSymbol].item(index) !== null);

            if (creating) {
              target[implSymbol][utils.indexedSetNew](index, indexedValue);
            } else {
              target[implSymbol][utils.indexedSetExisting](index, indexedValue);
            }
          } finally {
            ceReactionsPostSteps_helpers_custom_elements(globalObject);
          }

          return true;
        }
      }

      let ownDesc;

      if (utils.isArrayIndexPropName(P)) {
        const index = P >>> 0;
        const indexedValue = target[implSymbol].item(index);

        if (indexedValue !== null) {
          ownDesc = {
            writable: true,
            enumerable: true,
            configurable: true,
            value: utils.tryWrapperForImpl(indexedValue)
          };
        }
      }

      if (ownDesc === undefined) {
        ownDesc = Reflect.getOwnPropertyDescriptor(target, P);
      }

      if (ownDesc === undefined) {
        const parent = Reflect.getPrototypeOf(target);

        if (parent !== null) {
          return Reflect.set(parent, P, V, receiver);
        }

        ownDesc = {
          writable: true,
          enumerable: true,
          configurable: true,
          value: undefined
        };
      }

      if (!ownDesc.writable) {
        return false;
      }

      if (!utils.isObject(receiver)) {
        return false;
      }

      const existingDesc = Reflect.getOwnPropertyDescriptor(receiver, P);
      let valueDesc;

      if (existingDesc !== undefined) {
        if (existingDesc.get || existingDesc.set) {
          return false;
        }

        if (!existingDesc.writable) {
          return false;
        }

        valueDesc = {
          value: V
        };
      } else {
        valueDesc = {
          writable: true,
          enumerable: true,
          configurable: true,
          value: V
        };
      }

      return Reflect.defineProperty(receiver, P, valueDesc);
    }

    defineProperty(target, P, desc) {
      if (typeof P === "symbol") {
        return Reflect.defineProperty(target, P, desc);
      }

      const globalObject = this._globalObject;

      if (utils.isArrayIndexPropName(P)) {
        if (desc.get || desc.set) {
          return false;
        }

        const index = P >>> 0;
        let indexedValue = desc.value;

        if (indexedValue === null || indexedValue === undefined) {
          indexedValue = null;
        } else {
          indexedValue = HTMLOptionElement.convert(globalObject, indexedValue, {
            context: "Failed to set the " + index + " property on 'HTMLSelectElement': The provided value"
          });
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const creating = !(target[implSymbol].item(index) !== null);

          if (creating) {
            target[implSymbol][utils.indexedSetNew](index, indexedValue);
          } else {
            target[implSymbol][utils.indexedSetExisting](index, indexedValue);
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }

        return true;
      }

      return Reflect.defineProperty(target, P, desc);
    }

    deleteProperty(target, P) {
      if (typeof P === "symbol") {
        return Reflect.deleteProperty(target, P);
      }

      const globalObject = this._globalObject;

      if (utils.isArrayIndexPropName(P)) {
        const index = P >>> 0;
        return !(target[implSymbol].item(index) !== null);
      }

      return Reflect.deleteProperty(target, P);
    }

    preventExtensions() {
      return false;
    }

  }

  const Impl = _HTMLSelectElementImplDew();

  return exports;
}