import { dew as _utilsDew } from "../../utils";
import { dew as _HTMLElementImplDew } from "./HTMLElement-impl";
import { dew as _WindowEventHandlersImplDew } from "./WindowEventHandlers-impl";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const {
    mixin
  } = _utilsDew();

  const HTMLElementImpl = _HTMLElementImplDew().implementation;

  const WindowEventHandlersImpl = _WindowEventHandlersImplDew().implementation;

  class HTMLFrameSetElementImpl extends HTMLElementImpl {
    constructor(...args) {
      super(...args);

      this._proxyWindowEventsToWindow();
    }

  }

  mixin(HTMLFrameSetElementImpl.prototype, WindowEventHandlersImpl.prototype);
  exports = {
    implementation: HTMLFrameSetElementImpl
  };
  return exports;
}