import * as _os2 from "os";

var _os = "default" in _os2 ? _os2.default : _os2;

var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const os = _os;
  exports.implementation = class NavigatorConcurrentHardwareImpl {
    get hardwareConcurrency() {
      return os.cpus().length;
    }

  };
  return exports;
}