import { dew as _UIEventImplDew } from "./UIEvent-impl";
import { dew as _FocusEventInitDew } from "../generated/FocusEventInit";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;

  const UIEventImpl = _UIEventImplDew().implementation;

  const FocusEventInit = _FocusEventInitDew();

  class FocusEventImpl extends UIEventImpl {}

  FocusEventImpl.defaultInit = FocusEventInit.convert(undefined, undefined);
  exports.implementation = FocusEventImpl;
  return exports;
}