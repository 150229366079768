import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _htmlConstructorDew } from "../helpers/html-constructor.js";
import { dew as _customElementsDew } from "../helpers/custom-elements.js";
import { dew as _documentBaseUrlDew } from "../helpers/document-base-url.js";
import * as _whatwgUrl2 from "whatwg-url";

var _whatwgUrl = "default" in _whatwgUrl2 ? _whatwgUrl2.default : _whatwgUrl2;

import { dew as _stringsDew } from "../helpers/strings.js";
import { dew as _HTMLElementDew } from "./HTMLElement.js";
import { dew as _HTMLImageElementImplDew } from "../nodes/HTMLImageElement-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const HTMLConstructor_helpers_html_constructor = _htmlConstructorDew().HTMLConstructor;

  const ceReactionsPreSteps_helpers_custom_elements = _customElementsDew().ceReactionsPreSteps;

  const ceReactionsPostSteps_helpers_custom_elements = _customElementsDew().ceReactionsPostSteps;

  const parseURLToResultingURLRecord_helpers_document_base_url = _documentBaseUrlDew().parseURLToResultingURLRecord;

  const serializeURLwhatwg_url = _whatwgUrl.serializeURL;

  const parseNonNegativeInteger_helpers_strings = _stringsDew().parseNonNegativeInteger;

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;

  const HTMLElement = _HTMLElementDew();

  const interfaceName = "HTMLImageElement";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'HTMLImageElement'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["HTMLImageElement"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {
    HTMLElement._internalSetup(wrapper, globalObject);
  };

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class HTMLImageElement extends globalObject.HTMLElement {
      constructor() {
        return HTMLConstructor_helpers_html_constructor(globalObject, interfaceName, new.target);
      }

      get alt() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get alt' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "alt");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set alt(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set alt' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'alt' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "alt", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get src() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get src' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "src");

          if (value === null) {
            return "";
          }

          const urlRecord = parseURLToResultingURLRecord_helpers_document_base_url(value, esValue[implSymbol]._ownerDocument);

          if (urlRecord !== null) {
            return serializeURLwhatwg_url(urlRecord);
          }

          return conversions.USVString(value);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set src(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set src' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'src' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "src", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get srcset() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get srcset' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "srcset");
          return value === null ? "" : conversions.USVString(value);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set srcset(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set srcset' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'srcset' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "srcset", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get sizes() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get sizes' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "sizes");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set sizes(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set sizes' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'sizes' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "sizes", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get crossOrigin() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get crossOrigin' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "crossorigin");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set crossOrigin(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set crossOrigin' called on an object that is not a valid instance of HTMLImageElement.");
        }

        if (V === null || V === undefined) {
          V = null;
        } else {
          V = conversions["DOMString"](V, {
            context: "Failed to set the 'crossOrigin' property on 'HTMLImageElement': The provided value",
            globals: globalObject
          });
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "crossorigin", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get useMap() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get useMap' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "usemap");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set useMap(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set useMap' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'useMap' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "usemap", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get isMap() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get isMap' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol].hasAttributeNS(null, "ismap");
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set isMap(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set isMap' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["boolean"](V, {
          context: "Failed to set the 'isMap' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          if (V) {
            esValue[implSymbol].setAttributeNS(null, "ismap", "");
          } else {
            esValue[implSymbol].removeAttributeNS(null, "ismap");
          }
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get width() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get width' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["width"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set width(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set width' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'width' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["width"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get height() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get height' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          return esValue[implSymbol]["height"];
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set height(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set height' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'height' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol]["height"] = V;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get naturalWidth() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get naturalWidth' called on an object that is not a valid instance of HTMLImageElement.");
        }

        return esValue[implSymbol]["naturalWidth"];
      }

      get naturalHeight() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get naturalHeight' called on an object that is not a valid instance of HTMLImageElement.");
        }

        return esValue[implSymbol]["naturalHeight"];
      }

      get complete() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get complete' called on an object that is not a valid instance of HTMLImageElement.");
        }

        return esValue[implSymbol]["complete"];
      }

      get currentSrc() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get currentSrc' called on an object that is not a valid instance of HTMLImageElement.");
        }

        return esValue[implSymbol]["currentSrc"];
      }

      get name() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get name' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "name");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set name(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set name' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'name' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "name", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get lowsrc() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get lowsrc' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "lowsrc");

          if (value === null) {
            return "";
          }

          const urlRecord = parseURLToResultingURLRecord_helpers_document_base_url(value, esValue[implSymbol]._ownerDocument);

          if (urlRecord !== null) {
            return serializeURLwhatwg_url(urlRecord);
          }

          return conversions.USVString(value);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set lowsrc(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set lowsrc' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'lowsrc' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "lowsrc", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get align() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get align' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "align");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set align(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set align' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'align' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "align", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get hspace() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get hspace' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "hspace");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set hspace(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set hspace' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'hspace' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "hspace", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get vspace() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get vspace' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          let value = esValue[implSymbol].getAttributeNS(null, "vspace");

          if (value === null) {
            return 0;
          }

          value = parseNonNegativeInteger_helpers_strings(value);
          return value !== null && value >= 0 && value <= 2147483647 ? value : 0;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set vspace(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set vspace' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["unsigned long"](V, {
          context: "Failed to set the 'vspace' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const n = V <= 2147483647 ? V : 0;
          esValue[implSymbol].setAttributeNS(null, "vspace", String(n));
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get longDesc() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get longDesc' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "longdesc");

          if (value === null) {
            return "";
          }

          const urlRecord = parseURLToResultingURLRecord_helpers_document_base_url(value, esValue[implSymbol]._ownerDocument);

          if (urlRecord !== null) {
            return serializeURLwhatwg_url(urlRecord);
          }

          return conversions.USVString(value);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set longDesc(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set longDesc' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["USVString"](V, {
          context: "Failed to set the 'longDesc' property on 'HTMLImageElement': The provided value",
          globals: globalObject
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "longdesc", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      get border() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get border' called on an object that is not a valid instance of HTMLImageElement.");
        }

        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          const value = esValue[implSymbol].getAttributeNS(null, "border");
          return value === null ? "" : value;
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

      set border(V) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'set border' called on an object that is not a valid instance of HTMLImageElement.");
        }

        V = conversions["DOMString"](V, {
          context: "Failed to set the 'border' property on 'HTMLImageElement': The provided value",
          globals: globalObject,
          treatNullAsEmptyString: true
        });
        ceReactionsPreSteps_helpers_custom_elements(globalObject);

        try {
          esValue[implSymbol].setAttributeNS(null, "border", V);
        } finally {
          ceReactionsPostSteps_helpers_custom_elements(globalObject);
        }
      }

    }

    Object.defineProperties(HTMLImageElement.prototype, {
      alt: {
        enumerable: true
      },
      src: {
        enumerable: true
      },
      srcset: {
        enumerable: true
      },
      sizes: {
        enumerable: true
      },
      crossOrigin: {
        enumerable: true
      },
      useMap: {
        enumerable: true
      },
      isMap: {
        enumerable: true
      },
      width: {
        enumerable: true
      },
      height: {
        enumerable: true
      },
      naturalWidth: {
        enumerable: true
      },
      naturalHeight: {
        enumerable: true
      },
      complete: {
        enumerable: true
      },
      currentSrc: {
        enumerable: true
      },
      name: {
        enumerable: true
      },
      lowsrc: {
        enumerable: true
      },
      align: {
        enumerable: true
      },
      hspace: {
        enumerable: true
      },
      vspace: {
        enumerable: true
      },
      longDesc: {
        enumerable: true
      },
      border: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "HTMLImageElement",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = HTMLImageElement;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: HTMLImageElement
    });
  };

  const Impl = _HTMLImageElementImplDew();

  return exports;
}