import * as _webidlConversions2 from "webidl-conversions";

var _webidlConversions = "default" in _webidlConversions2 ? _webidlConversions2.default : _webidlConversions2;

import { dew as _utilsDew } from "./utils.js";
import { dew as _HistoryImplDew } from "../window/History-impl.js";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  const conversions = _webidlConversions;

  const utils = _utilsDew();

  const implSymbol = utils.implSymbol;
  const ctorRegistrySymbol = utils.ctorRegistrySymbol;
  const interfaceName = "History";

  exports.is = value => {
    return utils.isObject(value) && utils.hasOwn(value, implSymbol) && value[implSymbol] instanceof Impl.implementation;
  };

  exports.isImpl = value => {
    return utils.isObject(value) && value instanceof Impl.implementation;
  };

  exports.convert = (globalObject, value, {
    context = "The provided value"
  } = {}) => {
    if (exports.is(value)) {
      return utils.implForWrapper(value);
    }

    throw new globalObject.TypeError(`${context} is not of type 'History'.`);
  };

  function makeWrapper(globalObject, newTarget) {
    let proto;

    if (newTarget !== undefined) {
      proto = newTarget.prototype;
    }

    if (!utils.isObject(proto)) {
      proto = globalObject[ctorRegistrySymbol]["History"].prototype;
    }

    return Object.create(proto);
  }

  exports.create = (globalObject, constructorArgs, privateData) => {
    const wrapper = makeWrapper(globalObject);
    return exports.setup(wrapper, globalObject, constructorArgs, privateData);
  };

  exports.createImpl = (globalObject, constructorArgs, privateData) => {
    const wrapper = exports.create(globalObject, constructorArgs, privateData);
    return utils.implForWrapper(wrapper);
  };

  exports._internalSetup = (wrapper, globalObject) => {};

  exports.setup = (wrapper, globalObject, constructorArgs = [], privateData = {}) => {
    privateData.wrapper = wrapper;

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: new Impl.implementation(globalObject, constructorArgs, privateData),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper;
  };

  exports.new = (globalObject, newTarget) => {
    const wrapper = makeWrapper(globalObject, newTarget);

    exports._internalSetup(wrapper, globalObject);

    Object.defineProperty(wrapper, implSymbol, {
      value: Object.create(Impl.implementation.prototype),
      configurable: true
    });
    wrapper[implSymbol][utils.wrapperSymbol] = wrapper;

    if (Impl.init) {
      Impl.init(wrapper[implSymbol]);
    }

    return wrapper[implSymbol];
  };

  const exposed = new Set(["Window"]);

  exports.install = (globalObject, globalNames) => {
    if (!globalNames.some(globalName => exposed.has(globalName))) {
      return;
    }

    const ctorRegistry = utils.initCtorRegistry(globalObject);

    class History {
      constructor() {
        throw new globalObject.TypeError("Illegal constructor");
      }

      go() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'go' called on an object that is not a valid instance of History.");
        }

        const args = [];
        {
          let curArg = arguments[0];

          if (curArg !== undefined) {
            curArg = conversions["long"](curArg, {
              context: "Failed to execute 'go' on 'History': parameter 1",
              globals: globalObject
            });
          } else {
            curArg = 0;
          }

          args.push(curArg);
        }
        return esValue[implSymbol].go(...args);
      }

      back() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'back' called on an object that is not a valid instance of History.");
        }

        return esValue[implSymbol].back();
      }

      forward() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'forward' called on an object that is not a valid instance of History.");
        }

        return esValue[implSymbol].forward();
      }

      pushState(data, title) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'pushState' called on an object that is not a valid instance of History.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'pushState' on 'History': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["any"](curArg, {
            context: "Failed to execute 'pushState' on 'History': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'pushState' on 'History': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[2];

          if (curArg !== undefined) {
            if (curArg === null || curArg === undefined) {
              curArg = null;
            } else {
              curArg = conversions["USVString"](curArg, {
                context: "Failed to execute 'pushState' on 'History': parameter 3",
                globals: globalObject
              });
            }
          } else {
            curArg = null;
          }

          args.push(curArg);
        }
        return esValue[implSymbol].pushState(...args);
      }

      replaceState(data, title) {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'replaceState' called on an object that is not a valid instance of History.");
        }

        if (arguments.length < 2) {
          throw new globalObject.TypeError(`Failed to execute 'replaceState' on 'History': 2 arguments required, but only ${arguments.length} present.`);
        }

        const args = [];
        {
          let curArg = arguments[0];
          curArg = conversions["any"](curArg, {
            context: "Failed to execute 'replaceState' on 'History': parameter 1",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[1];
          curArg = conversions["DOMString"](curArg, {
            context: "Failed to execute 'replaceState' on 'History': parameter 2",
            globals: globalObject
          });
          args.push(curArg);
        }
        {
          let curArg = arguments[2];

          if (curArg !== undefined) {
            if (curArg === null || curArg === undefined) {
              curArg = null;
            } else {
              curArg = conversions["USVString"](curArg, {
                context: "Failed to execute 'replaceState' on 'History': parameter 3",
                globals: globalObject
              });
            }
          } else {
            curArg = null;
          }

          args.push(curArg);
        }
        return esValue[implSymbol].replaceState(...args);
      }

      get length() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get length' called on an object that is not a valid instance of History.");
        }

        return esValue[implSymbol]["length"];
      }

      get state() {
        const esValue = this !== null && this !== undefined ? this : globalObject;

        if (!exports.is(esValue)) {
          throw new globalObject.TypeError("'get state' called on an object that is not a valid instance of History.");
        }

        return esValue[implSymbol]["state"];
      }

    }

    Object.defineProperties(History.prototype, {
      go: {
        enumerable: true
      },
      back: {
        enumerable: true
      },
      forward: {
        enumerable: true
      },
      pushState: {
        enumerable: true
      },
      replaceState: {
        enumerable: true
      },
      length: {
        enumerable: true
      },
      state: {
        enumerable: true
      },
      [Symbol.toStringTag]: {
        value: "History",
        configurable: true
      }
    });
    ctorRegistry[interfaceName] = History;
    Object.defineProperty(globalObject, interfaceName, {
      configurable: true,
      writable: true,
      value: History
    });
  };

  const Impl = _HistoryImplDew();

  return exports;
}